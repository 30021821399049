<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Generate Reports
        </v-btn>
      </template>
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-card-title>
            <span class="text-h5">Generate Report</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-toolbar-title>*Report</v-toolbar-title>
                  <v-select
                    label="Report Name"
                    :rules="rules.required"
                    solo
                    item-text="text"
                    @change="selectEntity"
                    :items="options"
                    return-object
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-toolbar-title>*Type</v-toolbar-title>
                  <v-select
                    :items="TypeOptions"
                    label="Report Type"
                    item-text="name"
                    item-value="id"
                    :rules="rules.required"
                    required
                    solo
                    v-model="selectedType"
                    @change="showSpecificInput"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  v-if="openSpecificInput && selectedEntity"
                >
                  <v-toolbar-title>{{
                    EntitySearchFieldLabel
                  }}</v-toolbar-title>
                  <v-autocomplete
                    :items="searchItems"
                    :search-input.sync="search"
                    :label="EntitySearchBy"
                    :rules="rules.required"
                    :item-text="key_name"
                    required
                    v-model="SearchedSelectedRecord"
                    solo
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="GenerateReport()">
              Generate
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Reports from "./Reports";
import EntityService from "@/core/services/entity.service";
import { getEntitySearchFieldAndLable } from "@/core/services/util.js";
export default {
  data: () => ({
    valid: true,
    dialog: false,
    selectedType: "",
    loading: false,
    openSpecificInput: false,
    val: "",
    search: null,
    options: Reports,
    searchItems: [],
    key_name: "",
    selectedEntity: "",
    EntitySearchFieldLabel: "Search Report By Entity",
    EntitySearchBy: "Search Report By Entity",
    TypeOptions: [
      { name: "All But Not Dormant", id: "ForAll" },
      { name: "All (included Dormant)", id: "ForAllWithNoFilter" },
      { name: "Specific", id: "Specific" },
    ],
    SearchedSelectedRecord: "",
    rules: {
      required: [(v) => !!v || "Field is required"],
    },
  }),
  watch: {
    search(val) {
      val && val !== this.select && this.searchEntityRelatedFeild(val);
    },
  },
  methods: {
    showSpecificInput() {
      if (this.selectedType == "Specific") {
        this.openSpecificInput = true;
        this.searchEntityRelatedFeild("");
      } else {
        this.SearchedSelectedRecord = "";
        this.openSpecificInput = false;
      }
    },
    selectEntity(val) {
      this.selectedEntity = val;
      let FieldAndLable = getEntitySearchFieldAndLable(val.text);
      this.key_name = FieldAndLable.SearchKey;
      this.EntitySearchBy = FieldAndLable.EntitySearchBy;
      this.EntitySearchFieldLabel = FieldAndLable.EntitySearchFieldLabel;
    },
    searchEntityRelatedFeild(inputValue) {
      this.loading = true;
      EntityService.SearchEntityRelated(
        this.selectedEntity.text,
        inputValue
      ).then((resp) => {
        this.searchItems = resp.data.records;
      });
      this.loading = false;
    },
    GenerateReport() {
      if (this.$refs.form.validate()) {
        if (this.selectedType && this.selectedType != "Specific") {
          EntityService.GenerateReportService(
            this.selectedEntity.ReportAction,
            this.selectedType
          ).then(() => {
            this.dialog = false;
          });
        } else {
          EntityService.GenerateReportService(
            this.selectedEntity.ReportAction,
            this.SearchedSelectedRecord.id
          ).then(() => {
            this.dialog = false;
          });
        }
      }
    },
  },
};
</script>
