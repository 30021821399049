<template>
  <v-row>
    <v-col cols="12">
      <v-card color="grey lighten-4" flat>
        <v-toolbar>
          <template v-if="this.$slots.leftContent">
            <slot name="leftContent"></slot>
          </template>
          <v-spacer></v-spacer>
          <template v-if="this.$slots.centerContent">
            <slot name="centerContent"></slot>
          </template>
          <template v-else>
            <v-toolbar-title>{{ entityName }}</v-toolbar-title>
          </template>
          <v-spacer></v-spacer>
          <template v-if="this.$slots.rightContent">
            <slot name="rightContent"></slot>
          </template>
          <template v-else>
            <v-btn @click="logout">Logout</v-btn>
          </template>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "TheHeader",
  computed: {
    ...mapState({
      entityName: (state) =>
        state.currentEntity?.entity?.title || "Entity Name",
    }),
  },
  methods: {
    logout() {
      this.logOut();
      this.$router.push({ name: "login" });
    },
    ...mapActions("auth", ["logOut"]),
  },
};
</script>
