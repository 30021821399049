<template>
<div>
  <span v-for="toast in toasts" :key="toast.id">
    <Toast :toast="toast" @removeToast="onRemove"/>
  </span>
</div>
</template>

<script>
import Toast from './Toast'
export default {
  name: 'Toaster',
  computed: {
    toasts () {
      return this.$store.state.toast.toasts
    }
  },
  components: {
    Toast
  },
  methods: {
    onRemove (id) {
      this.$store.commit('toast/removeToast', { id })
    },

    clearToastList () {
      if (this.toastsList.length) {
        this.$store.commit('toast/clearToasts')
      }
    },

    updateToast (status, id) {
      if (!status) {
        this.onRemove(id)
      }
    },
  }
}
</script>