import axios from 'axios'
import { UsersService } from '../../services/user.service'

export default {
    namespaced: true,
    state: {
        currentUser: getSavedState('auth.currentUser'),
        jwt: "",
        loggedIn: false,
    },

    mutations: {
        SET_CURRENT_USER(state, newValue) {
            state.currentUser = newValue ? newValue.user : null
            state.loggedIn = true
            saveState('auth.currentUser', newValue?.user)
            setDefaultAuthHeaders(newValue?.token)
        },
        LOGOUT_CURRENT_USER(state) {
            state.currentUser = null
            state.loggedIn = false
            localStorage.removeItem('user')
            localStorage.removeItem('auth.currentUser')
            localStorage.removeItem('jwt')
        },
        SETUP_JWT(state, { payload }) {
            state.jwt = payload
            window.localStorage.setItem('jwt', payload)
        },
    },
    actions: {
        logIn({ commit, dispatch }, { email, password } = {}) {
            return UsersService.login(email, password)
                .then(({ data }) => {
                    dispatch('setupJwt', data.token)
                    commit('SET_CURRENT_USER', data)
                })
                .catch((error) => {
                    commit("toast/newToast", { type: "error", message: "Email or Password invalid" }, { root: true });
                    throw error
                })
        },
        setupJwt(context, payload) {
            context.commit('SETUP_JWT', { context, payload })
        },
        logOut({ commit }) {
            // TODO: Removing role set by sign in         
            commit('LOGOUT_CURRENT_USER', '')
        },
    },
    logOut({ commit }) {
        commit('LOGOUT_CURRENT_USER', '')
    },
}
function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
function setDefaultAuthHeaders(state) {
    axios.defaults.headers.common.Authorization = state.jwt ? state.jwt : ''
}
function getSavedState(key) {
    const user = window.localStorage.getItem(key)
    if (user !== 'undefined') {
        return JSON.parse(user)
    } else {
        return null
    }
}