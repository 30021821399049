var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100vh"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{staticStyle:{"justify-content":"center","align-items":"center"}},[_c('v-card',{staticClass:"elevation-12",staticStyle:{"width":"400px","height":"400px"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Login form")])],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":"Email","rules":{
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":10,"error-messages":errors,"label":"Email","required":""},model:{value:(_vm.options.email),callback:function ($$v) {_vm.$set(_vm.options, "email", $$v)},expression:"options.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":7,"error-messages":errors,"label":"Password","type":'password',"autocomplete":"off","required":""},model:{value:(_vm.options.password),callback:function ($$v) {_vm.$set(_vm.options, "password", $$v)},expression:"options.password"}})]}}],null,true)}),_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.login(invalid)}}},[_vm._v("Log in")])],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer')],1)],1)],1),_c('Toaster')],1)}
var staticRenderFns = []

export { render, staticRenderFns }