import getcsvFileKeys from "./entity.utils.js"
let sidebar;
const is_admin = window.localStorage.getItem('is_admin')

if (is_admin == 'false') {
  sidebar = false;
} else if (is_admin == 'true') {
  sidebar = true;
}

// TODO: Need to fix the display order 

const keys = [{
  dataTableID: "programme_period_id",
  tooltip: "This is prp_id on the basis of which, this report is generated",
  label: "Programme Period ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "programme_period_summary_reports/by",
      fetchQuery: {
        $where: {
          $or: {
            programme_period_id: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['programme_period_id'],
      value: 'programme_period_id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        programme_period_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "programme_id",
  tooltip: "programme_id of respective programme_period",
  label: "Programme ID",
  columnType: "number",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2,
    columnType: "number"
  },
  filter: {
    displayOrder: 2,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "programmes/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        programme_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "programme_name",
  tooltip: "Programme Name associated with respective programme",
  label: "Programme Name",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3,
    columnType: "string"
  }
},
{
  dataTableID: "academy_id",
  tooltip: "Academy ID associated with respective programme_period",
  label: "Academy ID",
  columnType: "number",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4,
    columnType: "number"
  },
  filter: {
    displayOrder: 4,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "academies/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        academy_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "academy_name",
  tooltip: "Academy Name associated with respective academy",
  label: "Academy Name",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5,
    columnType: "string"
  }
},
{
  dataTableID: "users",
  tooltip: "Users of this Learning Journey",
  label: "Users",
  columnType: "number",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6,
    columnType: "array[map]",
    display: ["name", "email"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 6,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Users',
        value: 'with_users'
      },
      {
        label: 'Without Users',
        value: 'without_users'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_users: {
        $where: {
          users: {
            $not_equal: []
          }
        }
      },
      without_users: {
        $where: {
          users: []
        }
      },
    }
  }
},
{
  dataTableID: "users_count",
  tooltip: "Number of users",
  label: "Users Count",
  columnType: "number",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7,
    columnType: "number"
  },
  filter: {
    displayOrder: 7,
    columnType: "integerRange"
  }
},
{
  dataTableID: "starts_on",
  tooltip: "Starts on date of this learning journey",
  label: "Starts On",
  columnType: "date",
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 8,
    columnType: "dateRange"
  }
},
{
  dataTableID: "ends_on",
  tooltip: "Ends on date of this learning journey",
  label: "Ends On",
  columnType: "date",
  table: {
    displayOrder: 8
  },
  filter: {
    displayOrder: 9,
    columnType: "dateRange"
  }
},
{
  dataTableID: "components_list",
  tooltip: "All Components List, showing toolkits, tools, events or courses",
  label: "Components List",
  columnType: "string",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10,
    columnType: "array[map]",
    display: ["title", "component_type"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 10,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Components List',
        value: 'with_components_list'
      },
      {
        label: 'Without Components List',
        value: 'without_components_list'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_components_list: {
        $where: {
          components_list: {
            $not_equal: []
          }
        }
      },
      without_components_list: {
        $where: {
          components_list: []
        }
      },
    }
  }
},
{
  dataTableID: "core_components_list",
  tooltip: "Showing Components which are mandatory",
  label: "Core Components List",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11,
    columnType: "array[map]",
    display: ["title", "component_type"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 11,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Core Components List',
        value: 'with_core_components_list'
      },
      {
        label: 'Without Core Components List',
        value: 'without_core_components_list'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_core_components_list: {
        $where: {
          core_components_list: {
            $not_equal: []
          }
        }
      },
      without_core_components_list: {
        $where: {
          core_components_list: []
        }
      },
    }
  }
},
{
  dataTableID: "core_components_count",
  tooltip: "Total number of core components available",
  label: "Core Components Count",
  columnType: "number",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12,
    columnType: "number"
  },
  filter: {
    displayOrder: 12,
    columnType: "integerRange"
  }
},
{
  dataTableID: "users_completed_programme_count",
  tooltip: "Number of users who have completed the core components",
  label: "Users Completed(#)",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13,
    columnType: "number"
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "percent_completed_user",
  tooltip: "Percentage of the users who have completed the core components",
  label: "% Completed Users",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14,
    columnType: "number"
  },
  filter: {
    displayOrder: 14,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_completion",
  tooltip: "Average completion of the core components done",
  label: "Avg Completion",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15,
    columnType: "number"
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "percent_avg_completion",
  tooltip: "Percentage Average completion of the core components done",
  label: "% Avg Completion",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16,
    columnType: "number"
  },
  filter: {
    displayOrder: 16,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_courses_enroll",
  tooltip: "Average courses enrolled in this journey",
  label: "Avg Courses Enrolled",
  columnType: "number",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17,
    columnType: "number"
  },
  filter: {
    displayOrder: 17,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_courses_completed",
  tooltip: "Average courses completed in this journey",
  label: "Avg Courses Completion",
  columnType: "number",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 18,
    columnType: "number"
  },
  filter: {
    displayOrder: 18,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_signin_count",
  tooltip: "Average user signin count in this programme period",
  label: "Avg Signin Count",
  columnType: "number",
  create: {
    displayOrder: 18
  },
  table: {
    displayOrder: 19,
    columnType: "number"
  },
  filter: {
    displayOrder: 19,
    columnType: "integerRange"
  }
},
{
  dataTableID: "total_signin_count",
  tooltip: "Total user signin count in this programme period",
  label: "Total Signin Count",
  columnType: "integer",
  create: {
    displayOrder: 19
  },
  table: {
    displayOrder: 20,
    columnType: "integer"
  },
  filter: {
    displayOrder: 20,
    columnType: "integerRange"
  }
},
{
  dataTableID: "app_usage",
  tooltip: "Total app usage",
  label: "App Usage",
  columnType: "number",
  create: {
    displayOrder: 20
  },
  table: {
    displayOrder: 21,
    columnType: "integer"
  },
  filter: {
    displayOrder: 21,
    columnType: "integerRange"
  }
},
{
  dataTableID: "most_popular_courses",
  tooltip: "Top 3 courses of this journey",
  label: "Most Popular Courses",
  columnType: "string",
  create: {
    displayOrder: 21
  },
  table: {
    displayOrder: 22,
    columnType: "array[map]",
    display: ["name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 22,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Most Popular Courses',
        value: 'with_most_popular_courses'
      },
      {
        label: 'Without Most Popular Courses',
        value: 'without_most_popular_courses'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_most_popular_courses: {
        $where: {
          most_popular_courses: {
            $not_equal: []
          }
        }
      },
      without_most_popular_courses: {
        $where: {
          most_popular_courses: []
        }
      },
    }
  }
},
{
  dataTableID: "most_popular_toolkits",
  tooltip: "Top 3 toolkits of this journey",
  label: "Most Popular Toolkits",
  columnType: "string",
  create: {
    displayOrder: 22
  },
  table: {
    displayOrder: 23,
    columnType: "array[map]",
    display: ["name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 23,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Most Popular Toolkits',
        value: 'with_most_popular_toolkits'
      },
      {
        label: 'Without Most Popular Toolkits',
        value: 'without_most_popular_toolkits'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_most_popular_toolkits: {
        $where: {
          most_popular_toolkits: {
            $not_equal: []
          }
        }
      },
      without_most_popular_toolkits: {
        $where: {
          most_popular_toolkits: []
        }
      },
    }
  }
},
{
  dataTableID: "most_popular_tools",
  tooltip: "Top 3 tools of this journey",
  label: "Most Popular Tools",
  columnType: "string",
  create: {
    displayOrder: 23
  },
  table: {
    displayOrder: 24,
    columnType: "array[map]",
    display: ["name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 24,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Most Popular Tools',
        value: 'with_most_popular_tools'
      },
      {
        label: 'Without Most Popular Tools',
        value: 'without_most_popular_tools'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_most_popular_tools: {
        $where: {
          most_popular_tools: {
            $not_equal: []
          }
        }
      },
      without_most_popular_tools: {
        $where: {
          most_popular_tools: []
        }
      },
    }
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this data inserted or created?",
  label: "Inserted At",
  columnType: "date",
  table: {
    displayOrder: 24
  },
  filter: {
    displayOrder: 25,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  table: {
    displayOrder: 25
  },
  filter: {
    displayOrder: 26,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 26
  },
  filter: {
    displayOrder: 27,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "all_programme",
  endPoint: "programme_period_summary_reports/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Programme Period Summary Reports",
  name: "programme_period_summary",
  actions: {
    sort: {
      endPoint: "programme_period_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "programme_period_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "programme_period_summary_reports"
    },
    filter: {
      endPoint: "programme_period_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "programme_period_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "programme_period_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};
