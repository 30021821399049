import Vue from "vue";
import Vuex from "vuex";
import entities from "@/core/entities/index";
import EntityService from "@/core/services/entity.service";
import MessageAnalyticsService from "@/core/services/message_analytics.service";
import * as _ from "lodash";

import modules from "./modules/index";
import AppStore from "@/app/store/index.js";

import { generateCleanQuery } from '@/core/services/util.js';
import { COLUMN_TYPE } from '@/core/services/consts.js';

Vue.use(Vuex);

const state = {
  ...AppStore.state,
  sidebarShow: "responsive",
  sidebarMinimize: false,
  currentEntity: {},
  currentTabEntity: {},
  entityDetails: {},
  entityFilters: [],
  entityRows: [],
  is_loading: false,
  defaultEntityName: 'Entity Name',
  modalFields: []
};

const mutations = {
  ...AppStore.mutations,
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  setCurrentModalData(state, payload) {
    state.modalFields = payload
  }
};

const actions = {
  async loadEntity({ state, commit }, payload) {
    commit("set", ["entityFilters", []]);
    const entity = typeof payload == "string" ? payload : payload.entity;
    let isTab = payload.tab === undefined ? false : payload.tab;
    let endPoint = null;
    let query = {};

    await commit("set", ["is_loading", true]);

    if (isTab) {
      await commit("set", ["currentTabEntity", entities[entity]]);
      endPoint = isTab.endPoint;
      query = isTab.query || {};
    } else {
      await commit("set", ["currentEntity", entities[entity]]);
      endPoint = state.currentEntity.entity?.actions?.get?.endPoint;
      query = state.currentEntity.entity.actions?.get?.query || {};
    }

    if (payload && payload.keys) {
      query.$where = {};
      Object.keys(payload.keys).forEach(key => {
        if (Array.isArray(payload.keys[key]) && payload.keys[key].length > 0) {
          const revKeys = [...payload.keys[key]].reverse();

          let currentPath = {};
          let fullPath = {};

          revKeys.forEach(objectKeyValue => {
            if (objectKeyValue == ":id") {
              fullPath[key] = payload.value;
            } else {
              currentPath = fullPath;
              fullPath = {};

              fullPath[objectKeyValue] = currentPath;
            }
          });

          query = _.merge(query, fullPath);
        } else {
          if (payload.keys[key] == ":id") {
            query.$where[key] = payload.value;
          } else {
            query.$where[key] = payload.keys[key];
          }
        }
      });
    }

    if (!isTab) {
      const filterFields = JSON.parse(
        localStorage.getItem(`${state.currentEntity?.entity?.title}_filters`)
      );
      if (filterFields) {
        query = getQueryByType(filterFields, query);
        commit("set", ["entityFilters", filterFields]);
      }
    }

    query.$limit = payload.pagination?.limit || 10;
    query.$skip = payload.pagination?.skip || 0;

    return new Promise((resolve, reject) => {
      EntityService.loadEntityDetails(endPoint, query)
        .then(response => {
          const timeStart = new Date();
          let myFlattenRecords = response.data.records;
          const timeEnd = new Date();
          console.log("exec time =" + (timeEnd - timeStart) + "ms");
          if (isTab) {
            myFlattenRecords = filterFieldSelection(
              myFlattenRecords,
              isTab.viewFields
            );
          } else if (state.currentEntity.keys?.length > 0) {
            myFlattenRecords = filterFieldSelection(
              myFlattenRecords,
              state.currentEntity.keys
            );
          }

          commit("set", ["entityRows", myFlattenRecords]);
          resolve(response.data);
        })
        .catch(error => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  setCurrentEntity({ commit }, payload) {
    commit("set", ["currentEntity", entities[payload.entity]]);
  },

  downloadEntityCsv({ state, commit }) {
    const filterFields = JSON.parse(
      localStorage.getItem(`${state.currentEntity?.entity?.title}_filters`)
    );
    let query = {};
    if (filterFields) {
      query = getQueryByType(filterFields, query);
    }

    return new Promise((resolve) => {
      query.csv_file_headers = state.currentEntity?.entity?.actions.csvDownload.query.csvFileKeys
      EntityService.downloadEntityCsv(
        state.currentEntity?.entity?.actions.csvDownload.endPoint,
        query
      )
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "" +
            state.currentEntity.entity.name +
            "_" +
            (new Date().getTime() / 1000).toFixed(0) +
            ".csv"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          resolve(response)
        })
        .catch(error => {
          commit("toast/newToast", { type: "error", message: error.message });
        });
    })
  },

  emailEntityCsv({ state, commit }) {
    const filterFields = JSON.parse(
      localStorage.getItem(`${state.currentEntity?.entity?.title}_filters`)
    );
    let query = {};
    if (filterFields) {
      query = getQueryByType(filterFields, query);
    }

    return new Promise((resolve) => {
      query.csv_file_headers = state.currentEntity?.entity?.actions.csvEmail.query.csvFileKeys
      query.report_name = state.currentEntity.entity.name
      EntityService.emailEntityCsv(
        state.currentEntity?.entity?.actions.csvEmail.endPoint,
        query
      )
        .then(response => {
          commit("toast/newToast", { type: "success", message: response.data });
          resolve(response)
        })
        .catch(error => {
          commit("toast/newToast", { type: "error", message: error.message });
        });
    })
  },

  async loadEntityTabs({ state, commit }, payload) {
    await commit("set", ["is_loading", true]);
    await commit("set", ["currentEntity", entities[payload.entity]]);

    return new Promise((resolve, reject) => {
      const endPoint = state.currentEntity.entity.actions.get.endPoint;
      const query = { ...state.currentEntity.entity.actions?.get?.query } || {};

      if (payload && payload.keys) {
        query.$where = {};
        Object.keys(payload.keys).forEach(key => {
          if (payload.keys[key] == ":id") {
            query.$where[key] = payload.value;
          } else {
            query.$where[key] = payload.keys[key];
          }
        });
      }

      EntityService.loadEntityDetails(endPoint, query)
        .then(response => {
          const results = response.data.records.map(item => {
            return state.currentEntity.keys.reduce((acc, key) => {
              return { ...acc, [key.dataTableID]: item[key.dataTableID] };
            }, {});
          })?.[0];

          commit("set", ["entityDetails", results]);
          resolve(results);
        })
        .catch(error => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  async reloadEntity({ state, commit }, payload) {
    await commit("set", ["is_loading", true]);
    let endPoint = state.currentEntity?.entity?.actions?.get?.endPoint;
    let query = { ...state.currentEntity?.entity?.actions?.get?.query } || {};

    const filterFields = state.entityFilters;

    if (filterFields.length > 0) {
      endPoint = state.currentEntity?.entity?.actions?.filter?.endPoint;
      query = { ...state.currentEntity?.entity?.actions?.filter?.query } || {};
    }

    query = getQueryByType(filterFields, query);

    query.$limit = payload.pagination.limit;
    query.$skip = payload.pagination.skip;

    return new Promise((resolve, reject) => {
      EntityService.loadEntityDetails(endPoint, query)
        .then(response => {
          const timeStart = new Date();
          const results = response.data.records.map(item => {
            return state.currentEntity.keys.reduce((acc, key) => {
              return { ...acc, [key.dataTableID]: item[key.dataTableID] };
            }, {});
          });
          const timeEnd = new Date();

          console.log("exec time =" + (timeEnd - timeStart) + "ms");

          commit("set", ["entityRows", results]);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  async changePageEntityRows({ state, commit }, payload) {
    await commit("set", ["is_loading", true]);

    let endPoint = state.currentEntity?.entity?.actions?.get?.endPoint;
    let query = { ...state.currentEntity?.entity?.actions?.get?.query } || {};

    const filterFields = state.entityFilters;

    if (filterFields.length > 0) {
      endPoint = state.currentEntity?.entity?.actions?.filter?.endPoint;
      query = { ...state.currentEntity?.entity?.actions?.filter?.query } || {};
    }

    query = getQueryByType(filterFields, query);

    query.$skip = (payload.pageNo - 1) * payload.limit;
    query.$limit = payload.limit;

    return new Promise((resolve, reject) => {
      EntityService.loadEntityDetails(endPoint, query)
        .then(response => {
          const timeStart = new Date();
          const results = response.data.records.map(item => {
            return state.currentEntity.keys.reduce((acc, key) => {
              return { ...acc, [key.dataTableID]: item[key.dataTableID] };
            }, {});
          });
          const timeEnd = new Date();

          console.log("exec time =" + (timeEnd - timeStart) + "ms");

          commit("set", ["entityRows", results]);
          resolve(results);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  async resetEntity({ state, commit }, payload) {
    await commit("set", ["is_loading", true]);

    const endPoint = state.currentEntity?.entity?.actions?.get?.endPoint;
    const query = { ...state.currentEntity?.entity?.actions?.get?.query } || {};

    commit("set", ["entityFilters", []]);

    if (query.$where) {
      query.$where = {};
    }

    if (state.currentEntity?.entity?.title) {
      localStorage.setItem(
        `${state.currentEntity?.entity?.title}_filters`,
        null
      );
    }

    query.$limit = payload.pagination.limit;
    query.$skip = (payload.pagination.pageNo - 1) * payload.pagination.limit;

    return new Promise((resolve, reject) => {
      EntityService.loadEntityDetails(endPoint, query)
        .then(response => {
          const timeStart = new Date();
          const results = response.data.records.map(item => {
            return state.currentEntity.keys.reduce((acc, key) => {
              return { ...acc, [key.dataTableID]: item[key.dataTableID] };
            }, {});
          });
          const timeEnd = new Date();

          console.log("exec time =" + (timeEnd - timeStart) + "ms");

          commit("set", ["entityRows", results]);

          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  async removeFilter({ state, commit }, payload) {
    await commit("set", ["is_loading", true]);

    const endPoint = state.currentEntity?.entity?.actions?.filter?.endPoint;
    let query =
      { ...state.currentEntity?.entity?.actions?.filter?.query } || {};

    const filterFields = state.entityFilters.filter(
      field => field.on !== payload.chipID
    );

    if (state.currentEntity?.entity?.title) {
      localStorage.setItem(
        `${state.currentEntity?.entity?.title}_filters`,
        JSON.stringify(filterFields)
      );
    }

    query = getQueryByType(filterFields, query);

    query.$limit = payload.pagination.limit;
    query.$skip = (payload.pagination.pageNo - 1) * payload.pagination.limit;

    return new Promise((resolve, reject) => {
      EntityService.loadEntityDetails(endPoint, query)
        .then(response => {
          const timeStart = new Date();
          const results = response.data.records.map(item => {
            return state.currentEntity.keys.reduce((acc, key) => {
              return { ...acc, [key.dataTableID]: item[key.dataTableID] };
            }, {});
          });
          const timeEnd = new Date();

          console.log("exec time =" + (timeEnd - timeStart) + "ms");

          commit("set", ["entityFilters", filterFields]);
          commit("set", ["entityRows", results]);

          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  async addEntityRecord({ state, commit }, payload) {
    await commit("set", ["is_loading", true]);
    return new Promise((resolve, reject) => {
      EntityService.createEntityData(
        state.currentEntity.entity.actions.create.endPoint,
        payload.formData
      )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  filterAndOrderEntityRecords({ state, commit }, payload) {
    const { filterData } = payload;
    const filterFields = state.currentEntity.keys
      .filter(key => key.filter)
      .map(field => {
        let selectedFilterField = null;
        Object.keys(filterData).forEach(dataTableID => {
          if (dataTableID === field.dataTableID) {
            const columnType = field.filter.columnType || field.columnType;
            let selectedFilterInput = ''
            let filterQuery = "";

            switch (columnType) {
              case 'string':
              case 'number':
                if (field.filter.filterQuery) {
                  filterQuery = field.filter.filterQuery;
                } else {
                  filterQuery = null;
                }
                selectedFilterInput = "$input";
                break;
              case COLUMN_TYPE.selectWithCustomQuery:
                filterQuery = field.filter.filterQueries[filterData[dataTableID]];
                selectedFilterInput = "$input";
                break;
              case 'selectWithQuery':
              case COLUMN_TYPE.selectWithStaticValues:
                filterQuery = field.filter.filterQuery
                selectedFilterInput = field.filter.selectedFilterInput
                break;
              default:
                selectedFilterInput = "$input";
            }
            selectedFilterField = {
              on: dataTableID,
              type: columnType,
              query: filterQuery,
              value: filterData[dataTableID],
              selectedFilterInput,
              chipTitle: field.filter.chipTitle ? field.filter.chipTitle.replace(selectedFilterInput, filterData[dataTableID]) : `${dataTableID} = ${filterData[dataTableID]}`
            };
          }
        });
        return selectedFilterField;
      })
      .filter(column => (column ? true : false));
    const endPoint = state.currentEntity?.entity?.actions?.filter?.endPoint;
    let query =
      { ...state.currentEntity?.entity?.actions?.filter?.query } || {};

    query = getQueryByType(filterFields, query);

    if (payload.orderColumn != null) {
      if (JSON.stringify(payload?.orderColumn?.sortBy) != [] && JSON.stringify(payload?.orderColumn?.sortDesc) != []) {
        if (payload.orderColumn.sortDesc[0] == true) query.$order = { [payload.orderColumn.sortBy[0]]: '$desc' }
        if (payload.orderColumn.sortDesc[0] == false) query.$order = { [payload.orderColumn.sortBy[0]]: '$asc' }
      }
    }
    query.$limit = payload.pagination.limit;
    query.$skip = (payload.pagination.pageNo - 1) * payload.pagination.limit;

    return new Promise((resolve, reject) => {
      EntityService.loadEntityDetails(endPoint, query)
        .then(response => {
          let myFlattenRecords = response.data.records;
          if (state.currentEntity?.entity?.title) {
            localStorage.setItem(
              `${state.currentEntity?.entity?.title}_filters`,
              JSON.stringify(filterFields)
            );
          }

          commit("set", ["entityFilters", filterFields]);
          commit("set", ["entityRows", myFlattenRecords]);
          resolve(response.data);
        })
        .catch(error => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error);
        })
        .finally(() => {
          commit("set", ["is_loading", false]);
        });
    });
  },

  getInputRecords(__, payload) {
    return new Promise((resolve, reject) => {
      const query = generateCleanQuery(_.cloneDeep(payload.fetchQuery), payload?.selectedFilterInput || null, payload?.userInput || '');

      let $select = [];

      if (payload.select && payload.select.length > 0) {
        $select = payload.select.map(select => {
          return select.field;
        });

        query.$select = $select;
      }
      EntityService.getInputRecords(payload.endPoint, query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getAcademyList({ commit }) {
    return new Promise((resolve, reject) => {
      EntityService.getAcademyList(
        "academies/by", {
        $limit: 10,
        $skip: 0
      }
      )
        .then(response => {
          resolve(response.data.records)
        })
        .catch(error => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error)
        });
    })
  },

  getAverageSpendingTime({ commit }, params) {
    return new Promise((resolve, reject) => {
      MessageAnalyticsService.averageSpendingTime(
        "message_analytics/average_time_spending", params
      ).then((response) => { resolve(response.data.records) })
        .catch((error) => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error)
        })
    })
  },
  getDailyActiveUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      MessageAnalyticsService.dailyActiveUsers(
        "message_analytics/daily_active_users", params
      ).then((response) => { resolve(response.data) })
        .catch((error) => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error)
        })
    })
  },
  getRatioDailyMonthlyUsers({ commit }, params) {
    return new Promise((resolve, reject) => {
      MessageAnalyticsService.getRatioDailyMonthlyUsers(
        "message_analytics/ratio_daily_monthly_users", params
      ).then((response) => { resolve(response.data) })
        .catch((error) => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error)
        })
    })
  },
  getConversationsPerDay({ commit }, params) {
    return new Promise((resolve, reject) => {
      MessageAnalyticsService.conversationsPerDay(
        "message_analytics/conversations_per_day", params
      ).then((response) => { resolve(response.data) })
        .catch((error) => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error)
        })
    })
  },
  getMessagesCountInGroup({ commit }, params) {
    return new Promise((resolve, reject) => {
      MessageAnalyticsService.getMessagesCountInGroup(
        "message_analytics/total_number_of_group_messages", params
      ).then((response) => { resolve(response.data) })
        .catch((error) => {
          commit("toast/newToast", { type: "error", message: error.message });
          reject(error)
        })
    })
  },

}
export default new Vuex.Store({
  modules,
  state,
  actions,
  mutations
});

function getQueryByType(filterFields, query) {
  let newQuery = _.cloneDeep(query)
  if (filterFields.length > 0) {
    filterFields.forEach(filter => {
      switch (filter.type) {
        case "string":
        case "number":
          if (filter.query == null) {
            newQuery.$where[filter.on] = {
              $ilike: `%${filter.value}%`
            };
          } else {
            const query = generateCleanQuery(filter.query, filter.selectedFilterInput, filter.value);
            newQuery = _.merge(newQuery, _.cloneDeep(query))
          }
          break;
        case "select":
        case "dateRange": {
          const range = filter.value.split(" ~ ");
          newQuery.$where[filter.on] = {
            $between_equal: [range[0], range[1]]
          };
          break;
        }
        case "integerRange": {
          const range = filter.value.split(" ~ ");
          newQuery.$where[filter.on] = {
            $between_equal: [range[0], range[1]]
          };
          break;
        }
        case "date": {
          const dateTime = new Date(filter.value);
          const date = `${dateTime.getFullYear()}-${dateTime.getMonth() +
            1}-${dateTime.getDate()}`;
          newQuery.$where[filter.on] = filter.query
            ? { [filter.query]: date }
            : {
              $ilike: `${date}%`
            };
          break;
        }
        case "custom_date": {
          const dateTime = new Date(filter.value);
          newQuery.$where[filter.on] = dateTime
          break;
        }
        case "dateTimeRange": {
          const range = filter.value.split(" ~ ");
          newQuery.$where[filter.on] = {
            $between_equal: [range[0], range[1]]
          };
          break;
        }
        case "datetime": {
          newQuery.$where[filter.on] = filter.query
            ? { [filter.query]: filter.value }
            : filter.value;
          break;
        }
        case COLUMN_TYPE.selectWithCustomQuery:
          newQuery = _.merge(newQuery, _.cloneDeep(filter.query))
          break;
        case "selectWithQuery":
        case COLUMN_TYPE.selectWithStaticValues: {
          const query = generateCleanQuery(filter.query, filter.selectedFilterInput, filter.value);
          newQuery = _.merge(newQuery, _.cloneDeep(query))
          break
        }
      }
    });
  }

  return newQuery;
}

function filterFieldSelection(records, fields) {
  return records.map(record => {
    const returnObj = {};

    fields.forEach(field => {
      if (field.displayPath?.length > 0) {
        returnObj[field.dataTableID] = field.displayPath.reduce((acc, value) => {
          return acc[value];
        }, record);
      } else {
        returnObj[field.dataTableID] =
          record[field.dataTableID];
      }
    });
    return returnObj;
  });
}
