import axios from 'axios'
import { BASE_URL } from '@/.env'

export const HTTP = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})



export function get(url) {
  beforeRequest()
  return HTTP.get(url)
}

export function post(url, data) {
  beforeRequest()
  return HTTP.post(url, data)
}

export function put(url, data) {
  beforeRequest()
  return HTTP.put(url, data)
}

export function del(url) {
  beforeRequest()
  return HTTP.delete(url)
}

function beforeRequest() {
  addToken()
  addAcademy()
}
function addToken() {
  const jwt = window.localStorage.getItem('jwt') || ''
  if (jwt.length > 0) {
    HTTP.defaults.headers.common.Authorization = `Bearer ${jwt}`
  }
}

function addAcademy() {
  const currentTenant = window.localStorage.getItem('currentTenant') || ''
  if (currentTenant) {
    HTTP.defaults.headers["X-Academies"] = currentTenant
  } else {
    delete HTTP.defaults.headers["X-Academies"]
  }
}