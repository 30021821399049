import entities from "@/core/entities/index.js";

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const regEx = new RegExp("_", "g");

// const sidebarItems = Object.keys(entities)
//   .map(entity => {
//     if (entities[entity].sidebar) {
//       return {
//         _name: "CSidebarNavItem",
//         name: entity.replace(regEx, " ").capitalize(),
//         to: `/${entity}`,
//         icon: "cil-speedometer",
//         exact: false
//       };
//     }
//   })
//   .filter(item => item !== undefined);

// const sidebarContent = [
//   {
//     _name: 'CSidebarNav',
//     _children: [
//       {
//         _name: 'CSidebarNavItem',
//         name: 'Dashboard',
//         to: '/dashboard',
//         icon: 'cil-speedometer',
//         // badge: {
//         //   color: 'primary',
//         //   text: 'NEW'
//         // }
//       },
//       ...sidebarItems
//     ]
//   }
// ]
const sidebarItems = Object.keys(entities)
  .map(entity => {
    if (entities[entity].sidebar) {
      return {
        _name: "CSidebarNavItem",
        text: entity.replace(regEx, " ").capitalize(),
        to: `/${entity}`,
        icon: "mdi-label",
        exact: false
      };
    }
  })
  .filter(item => item !== undefined);
  let sidebarContent = []
  if (JSON.parse(localStorage.getItem("is_admin"))){
     sidebarContent = [
      {to: '/dashboard',icon: "mdi-label",text:"Dashboard"},
      {to: '/reports_generator',icon: "mdi-label",text:"Report Generation"},
      {to: '/message_analytics', icon: "mdi-label", text: "Message Analytics"},
      ...sidebarItems
    ];
  }else{
    sidebarContent = [
      {to: '/dashboard',icon: "mdi-label",text:"Dashboard"},
      ...sidebarItems
    ];
  }
export default sidebarContent;
