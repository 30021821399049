import * as _ from 'lodash'

// function _getStatusMessage (status) {
//   let message = ''
//   switch (status) {
//     case 200:
//       message = 'All done. Request successfully executed'
//       break
//     case 201:
//       message = 'Data successfully created'
//       break
//     case 400:
//       message = 'Bad Request'
//       break
//     case 401:
//       message = 'Need auth'
//       break
//     case 404:
//       message = 'Not found'
//       break
//     case 422:
//       message = 'Error occured when attempting !'
//       break
//     case 500:
//       message = 'Something went wrong. Try again later'
//       break
//     case 503:
//       message = 'Service unavailable. Try again later'
//       break
//     default:
//       message = 'Something wrong. Client default error message'
//       break
//   }
//   return message
// }

function _getResponseErrors() {
  const errors = []
  return errors
}

function _getResponseErrorMessage(error) {
  if (!error.response) {
    return 'Oops! Network Error. Try again later'
  }
  return 'Some error occured !'
}

export class ErrorWrapper extends Error {
  constructor(error) {
    super()
    this.errors = _getResponseErrors(error)
    this.meta = error.response ? error.response.data.meta : false
    this.code = error.response ? error.response?.data?.error?.code : false
    this.status = error.response ? error.response.status : false
    this.message = _getResponseErrorMessage(error)
  }
}

export function getEntitySearchFieldAndLable(entityName) {
  let result = {}
  switch (entityName) {
    case "Academy course period":
      result = { EntitySearchFieldLabel: "Generate report for all course periods of this academy", EntitySearchBy: "Search Academy By Name", SearchKey: "name" }
      break;
    case "Academy summary":
      result = { EntitySearchFieldLabel: "Generate report for this academy", EntitySearchBy: "Search Academy By Name", SearchKey: "name" }
      break;
    case "Academy tool":
      result = { EntitySearchFieldLabel: "Generate report for all tools of this academy", EntitySearchBy: "Search Academy By Name", SearchKey: "name" }
      break;
    case "Academy toolkit":
      result = { EntitySearchFieldLabel: "Generate report for all toolkits of this academy", EntitySearchBy: "Search Academy By Name", SearchKey: "name" }
      break;
    case "Academy user":
      result = { EntitySearchFieldLabel: "Generate report for all users of this academy", EntitySearchBy: "Search Academy By Name", SearchKey: "name" }
      break;
    case "Company report":
      result = { EntitySearchFieldLabel: "Generate report for this company", EntitySearchBy: "Search Company By Name", SearchKey: "name" }
      break;
    case "Course Period Summary":
      result = { EntitySearchFieldLabel: "Generate report for this specific course period", EntitySearchBy: "Search By Course Period ID", SearchKey: "id" }
      break;
    case "Course Summary":
      result = { EntitySearchFieldLabel: "Generate report for this course", EntitySearchBy: "Search Course By Title", SearchKey: "title" }
      break;
    case "Course period user summary":
      result = { EntitySearchFieldLabel: "Generate report for users of this course period", EntitySearchBy: "Search BY Course Period ID", SearchKey: "id" }
      break;
    case "Programme period summary":
      result = { EntitySearchFieldLabel: "Generate report for all program periods of this Programme", EntitySearchBy: "Search Programme By Name", SearchKey: "name" }
      break;
    case "Programme period user summary":
      result = { EntitySearchFieldLabel: "Generate report for all users of this Programme Period", EntitySearchBy: "Search Programme By Name", SearchKey: "name" }
      break;
    case "Tool Summary":
      result = { EntitySearchFieldLabel: "Generate report for this tool", EntitySearchBy: "Search Tool By Title", SearchKey: "title" }
      break;
    case "Tootkit Summary":
      result = { EntitySearchFieldLabel: "Generate report for this tool kit", EntitySearchBy: "Search Toolkit By Title", SearchKey: "title" }
      break;
    case "User Summary":
      result = { EntitySearchFieldLabel: "Generate report for this user", EntitySearchBy: "Search User By Name", SearchKey: "name" }
      break;
    case "User profile summary":
      result = { EntitySearchFieldLabel: "Generate report for this user", EntitySearchBy: "Search User By Name", SearchKey: "name" }
      break;
    default:
      result = { EntitySearchFieldLabel: entityName, EntitySearchBy: entityName }
  }
  return result
}
export function getEndpointAndQuery(entityName, filterInput) {
  let result = {}
  switch (entityName) {
    case "Academy course period":
      result = { Endpoint: "academies/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Academy summary":
      result = { Endpoint: "academies/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Academy tool":
      result = { Endpoint: "academies/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Academy toolkit":
      result = { Endpoint: "academies/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Academy user":
      result = { Endpoint: "academies/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Company report":
      result = { Endpoint: "companies/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Course Period Summary":
      result = { Endpoint: "course_periods/by", Query: { $where: { id: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Course Summary":
      result = { Endpoint: "courses/by", Query: { $where: { title: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Course period user summary":
      result = { Endpoint: "course_periods/by", Query: { $where: { id: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Programme period summary":
      result = { Endpoint: "programmes/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Programme period user summary":
      result = { Endpoint: "academies/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Tool Summary":
      result = { Endpoint: "tools/by", Query: { $where: { title: { $ilike: `%${filterInput}%` } } } }
      break;
    case "Tootkit Summary":
      result = { Endpoint: "toolkits/by", Query: { $where: { title: { $ilike: `%${filterInput}%` } } } }
      break;
    case "User Summary":
      result = { Endpoint: "users/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    case "User profile summary":
      result = { Endpoint: "users/by", Query: { $where: { name: { $ilike: `%${filterInput}%` } } } }
      break;
    default:
      result = { EntitySearchFieldLabel: entityName, EntitySearchBy: entityName }
  }
  return result
}

export function generateCleanQuery(query, filterInput, value = '') {
  let resultQuery = _.cloneDeep(query);

  Object.keys(query).forEach(key => {
    if (typeof query[key] === 'object') {
      const cleansedQuery = generateCleanQuery(query[key], filterInput, value)
      if (Object.keys(cleansedQuery).length) {
        resultQuery[key] = cleansedQuery
      } else {
        delete resultQuery[key]
      }
    } else if (query[key].includes(filterInput)) {
      if (value !== undefined && value !== null && value !== '') {
        resultQuery[key] = query[key].replace(filterInput, value)
      } else {
        delete resultQuery[key]
      }
    }
  })
  return resultQuery;
}
export function removeObjectKeys(filter, key) {
  let newFilters = {}
  for (let objectKey of Object.keys(filter)) {
    if (key != objectKey) {
      newFilters = { [objectKey]: filter[objectKey], ...newFilters }
    }
  }

  return newFilters
}
