export default {
  namespaced: true,
  
  state: {
    toasts: [],
  },

  mutations: {
    newToast (state, payload) {
      const toast = {
        id: new Date().getTime(),
        message: typeof payload === 'string' ? payload : payload.message,
        type: payload.type || 'default',
        duration: payload.duration || 2000,
        description: payload.description || null
      }
      state.toasts.push({ ...toast })
    },
  
    removeToast (state, payload) {
      state.toasts = state.toasts.filter(({ id }) => id !== payload.id)
    },
    clearToasts (state) {
      state.toasts = []
    }
  }
}