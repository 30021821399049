<template>
  <v-container fluid fill-height style="height: 100vh">
    <v-layout style="justify-content: center; align-items: center">
      <v-card class="elevation-12" style="width: 400px; height: 400px">
        <v-toolbar dark color="primary">
          <v-toolbar-title>Login form</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="submit">
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                :rules="{
                  required: true
                }"
              >
                <v-text-field
                  v-model="options.email"
                  :counter="10"
                  :error-messages="errors"
                  label="Email"
                  required
                ></v-text-field>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="password"
                :rules="{
                  required: true,
                }"
              >
                <v-text-field
                  v-model="options.password"
                  :counter="7"
                  :error-messages="errors"
                  label="Password"
                  :type="'password'"
                  autocomplete="off"
                  required
                ></v-text-field>
              </validation-provider>
              <v-btn @click.prevent="login(invalid)">Log in</v-btn>
            </form>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-layout>
    <Toaster />
  </v-container>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import Toaster from "../../core/components/base/Toaster";
import { mapActions } from "vuex";
export default {
  name: "SignInPage",

  components: {
    ValidationProvider,
    ValidationObserver,
    Toaster,
  },
  data() {
    return {
      msg: "Welcome to Index!!!",
      isShownModal: false,
      inputError: false,
      checkboxState: false,
      showText: false,
      errorMsg: null,
      userType: ["Student", "Teacher"],
      pagination: {
        limit: 20,
        offset: 0,
        total: 60,
      },
      options: {
        email: "",
        password: "",
        remember: false,
      },
      error: "",
    };
  },
  computed: {
    isLoading() {
      return false;
    },
  },

  methods: {
    login(invalid) {
      this.$refs.observer.validate();
      if (!invalid && this.options.email && this.options.password) {
        this.logIn({
          email: this.options.email,
          password: this.options.password,
        })
          .then(() => {
            this.$router.push({ name: "dashboard" });
          })
          .catch((error) => {
            if (error.code === 401) {
              this.errorMsg = error.message;
            }
          });
      } else {
        console.log(invalid);
      }
    },
    ...mapActions("auth", ["logIn"]),
  },
};
</script>