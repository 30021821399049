import { BASE_URL } from "@/.env";
import { CommonService } from "./common.service";
import { by } from "./custom.service";

export default class EntityService extends CommonService {
  static averageSpendingTime(endPoint, data = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(this.errorWrapper(error));
        });
    });
  }
  static dailyActiveUsers(endPoint, data = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(this.errorWrapper(error));
        });
    });
  }
  static getRatioDailyMonthlyUsers(endPoint, data = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(this.errorWrapper(error));
        });
    });
  }
  static conversationsPerDay(endPoint, data = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(this.errorWrapper(error));
        });
    });
  }
  static getMessagesCountInGroup(endPoint, data = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(this.errorWrapper(error));
        });
    });
  }
}
