const sidebar = false;

const entity = {
  title: "Academies",
  actions: {
    sort: {
      endPoint: "academies/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "academies/by",
      query: {
        $limit: 10,
        $skip: 0
      }
    },
    create: {
      endPoint: "academies"
    },
    filter: {
      endPoint: "academies/by",
      query: {
        $group: "id",
        $where: {},
        $limit: 10,
        $skip: 0
      }
    }
  }
};

const keys = [{
  dataTableID: "id",
  label: "Id",
  tooltip: "ID on the basis of which this report was generated",
  columnType: "string",
  table: {
    displayOrder: 1
  },
  filter: {
    displayOrder: 1,
    columnType: "selectWithQuery",
    matchType: "", // Values can be gte, lte, gt, lt, By default it is eq
    options: {
      select: [{
        field: "id"
      }],
      title: ["id"],
      value: "id",
      endPoint: "academies/by",
      separator: " ",
      initializer: "",
      query: {
        $where: {
          id: "$input"
        }
      },
      keyFinder: "$input"
    }
  }
},
{
  dataTableID: "name",
  tooltip: "Name of the academy",
  label: "Name",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  },
  filter: {
    displayOrder: 2
  }
},
{
  dataTableID: "description",
  tooltip: "Description of the academy",
  label: "Description",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  }
},
{
  dataTableID: "access_type",
  tooltip: "Academy's access type ",
  label: "Access Type",
  columnType: "number",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  }
},
{
  dataTableID: "is_dormant",
  tooltip: "Whether the academy is dormant or not?",
  label: "Is Dormant ?",
  columnType: "boolean",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  }
},
{
  dataTableID: "status",
  tooltip: "Status of the academy",
  label: "Status",
  columnType: "number",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "academies",
  endPoint: "academies/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
},
{
  entity: "programmes",
  title: "Programmes",
  endPoint: "programmes/by",
  queryFilter: {
    academy_id: ["$include", "academy_programmes", "$where", ":id"]
  },
  query: {
    $group: "id",
    $include: {
      academy_programmes: {
        $group: "id",
        $where: {}
      }
    }
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "name",
    label: "Name"
  },
  {
    dataTableID: "description",
    label: "Description"
  }
  ]
},
{
  entity: "programmes",
  title: "Ongoing Programmes",
  endPoint: "programmes/by",
  queryFilter: {
    academy_id: ["$include", "programme_periods", "$where", ":id"]
  },
  query: {
    $group: "id",
    $include: {
      programme_periods: {
        $group: "id",
        $where: {}
      }
    }
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "name",
    label: "Name"
  },
  {
    dataTableID: "description",
    label: "Description"
  }
  ]
},
{
  entity: "courses",
  title: "Courses",
  endPoint: "courses/by",
  queryFilter: {
    academy_id: ["$include", "course_periods", "$where", ":id"]
  },
  query: {
    $group: "id",
    $include: {
      course_periods: {
        $group: "id",
        $where: {}
      }
    }
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "title",
    label: "Title"
  },
  {
    dataTableID: "instructor",
    label: "Instructor"
  },
  {
    dataTableID: "description",
    label: "Description"
  },
  {
    dataTableID: "total_hours",
    label: "Total Hours"
  },
  {
    dataTableID: "acceptance_score",
    label: "Acceptance Score"
  },
  {
    dataTableID: "price_currency",
    label: "Price Currency"
  },
  {
    dataTableID: "price",
    label: "Price"
  },
  {
    dataTableID: "is_featured",
    label: "Featured ?"
  }
  ]
},
{
  entity: "users",
  title: "Users",
  endPoint: "users/by",
  queryFilter: {
    academy_id: ["$include", "academy_users", "$where", ":id"]
  },
  query: {
    $group: "id",
    $include: {
      academy_users: {
        $group: "id",
        $where: {}
      }
    }
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "name",
    label: "Name"
  },
  {
    dataTableID: "sign_in_count",
    label: "Sign In Count"
  }
  ]
},
{
  entity: "users",
  title: "Moderators",
  endPoint: "users/by",
  queryFilter: {
    academy_id: ["$include", "moderated_academies", "$where", ":id"]
  },
  query: {
    $group: "id",
    $include: {
      academy_users: {
        $group: "id",
        $where: {}
      }
    }
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "name",
    label: "Name"
  }
  ]
},
{
  entity: "academies",
  title: "Sub Academies",
  endPoint: "academies/by",
  queryFilter: {
    academy_id: ":id"
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "name",
    label: "Name"
  }
  ]
},
{
  entity: "tools",
  title: "Tools",
  endPoint: "tools/by",
  queryFilter: {
    academy_id: ["$include", "academy_tools", "$where", ":id"]
  },
  query: {
    $group: "id",
    $include: {
      academy_tools: {
        $group: "id",
        $where: {}
      }
    }
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  }]
},
{
  entity: "toolkits",
  title: "Tool Kits",
  endPoint: "toolkits/by",
  queryFilter: {
    academy_id: ["$include", "academy_toolkits", "$where", ":id"]
  },
  query: {
    $group: "id",
    $include: {
      academy_toolkits: {
        $group: "id",
        $where: {}
      }
    }
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "title",
    label: "Title"
  },
  {
    dataTableID: "description",
    label: "Description"
  }
  ]
},
{
  entity: "events",
  title: "Events",
  endPoint: "events/by",
  queryFilter: {
    academy_id: ":id"
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "title",
    label: "Title"
  },
  {
    dataTableID: "body",
    label: "Body"
  }
  ]
},
{
  entity: "events",
  title: "Announcements",
  endPoint: "events/by",
  queryFilter: {
    academy_id: ":id",
    type: 7
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "title",
    label: "Title"
  },
  {
    dataTableID: "body",
    label: "Body"
  }
  ]
},
{
  entity: "posts",
  title: "Posts",
  endPoint: "posts/by",
  queryFilter: {
    academy_id: ":id"
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "title",
    label: "Title"
  },
  {
    dataTableID: "body",
    label: "Body"
  },
  {
    dataTableID: "is_featured",
    label: "Featured ?"
  }
  ]
},
{
  entity: "relatedLinks",
  title: "Related Links",
  endPoint: "related_links/by",
  queryFilter: {
    academy_id: ":id"
  },
  viewFields: [{
    dataTableID: "id",
    label: "ID"
  },
  {
    dataTableID: "title",
    label: "Title"
  },
  {
    dataTableID: "url",
    label: "URL"
  }
  ]
}
];

export {
  sidebar,
  entity,
  keys,
  tabs
};