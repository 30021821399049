import { Socket } from 'phoenix'
import { BASE_URL } from './.env.js'
export default {
  install(Vue) {
    Vue.prototype.$connectSocket = function(url, token) {
      const socketURL = BASE_URL.replace("http", "ws").replace("/api", "")
      const socket = new Socket(`${socketURL}${url}`, {
        params: { token },
      })
      socket.connect()
      socket.channel(url)
      socket.onError(() => {
        socket.disconnect()
      })
      Vue.prototype.$socket = socket
    }
  },
}
