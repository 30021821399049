import getcsvFileKeys from "./entity.utils.js"
const sidebar = true;

const keys = [
  {
    dataTableID: "academy_id",
    tooltip: "Academy ID of this report",
    label: "Academy ID",
    columnType: "number",
    table: {
      displayOrder: 1
    },
    filter: {
      chipTitle: "academy_id ~= $input",
      displayOrder: 1,
      columnType: "selectWithQuery",
      dropdown: {
        endPoint: "academies/by",
        fetchQuery: {
          $where: {
            $or: {
              id: {
                $ilike: "%$input%"
              },
              name: {
                $ilike: "%$input%"
              }
            }
          }
        },
        label: ['id', 'name'],
        value: 'id',
        labelSeperator: ' - ',
      },
      filterQuery: {
        $where: {
          academy_id: {
            $ilike: '%$input%'
          }
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "academy_name",
    tooltip: "Academy Name associated with this academy",
    label: "Academy Name",
    columnType: "string",
    create: {
      displayOrder: 1
    },
    table: {
      displayOrder: 2
    }
  },
  {
    dataTableID: "user_id",
    tooltip: "User ID associated with this academy",
    label: "User ID",
    columnType: "number",
    create: {
      displayOrder: 2
    },
    table: {
      displayOrder: 3
    },
    filter: {
      chipTitle: "user_id ~= $input",
      displayOrder: 3,
      columnType: "selectWithQuery",
      dropdown: {
        endPoint: "users/by",
        fetchQuery: {
          $where: {
            $or: {
              id: {
                $ilike: "%$input%"
              },
              name: {
                $ilike: "%$input%"
              }
            }
          }
        },
        label: ['id', 'name'],
        value: 'id',
        labelSeperator: ' - ',
      },
      filterQuery: {
        $where: {
          user_id: {
            $ilike: '%$input%'
          }
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "first_name",
    tooltip: "First name of the user",
    label: "First Name",
    columnType: "string",
    create: {
      displayOrder: 3
    },
    table: {
      displayOrder: 4
    },
    filter: {
      displayOrder: 4
    }
  },
  {
    dataTableID: "last_name",
    tooltip: "Last name of the user",
    label: "Last Name",
    columnType: "string",
    create: {
      displayOrder: 4
    },
    table: {
      displayOrder: 5
    },
    filter: {
      displayOrder: 5
    }
  },
  {
    dataTableID: "user_name",
    tooltip: "Username of this user",
    label: "User Name",
    columnType: "string",
    create: {
      displayOrder: 5
    },
    table: {
      displayOrder: 6
    }
  },
  {
    dataTableID: "email",
    tooltip: "Email of the user",
    label: "Email",
    columnType: "string",
    create: {
      displayOrder: 6
    },
    table: {
      displayOrder: 7
    },
    filter: {
      chipTitle: "email ~= $input",
      displayOrder: 7,
      columnType: "selectWithQuery",
      dropdown: {
        endPoint: "users/by",
        fetchQuery: {
          $where: {
            $or: {
              id: {
                $ilike: "%$input%"
              },
              email: {
                $ilike: "%$input%"
              }
            }
          }
        },
        label: ['id', 'email'],
        value: 'email',
        labelSeperator: ' - ',
      },
      filterQuery: {
        $where: {
          email: {
            $ilike: '%$input%'
          }
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "group_label",
    tooltip: "Group label of the user",
    label: "Group Label",
    columnType: "string",
    create: {
      displayOrder: 7
    },
    table: {
      displayOrder: 8
    },
    filter: {
      displayOrder: 8
    }
  },
  {
    dataTableID: "status",
    tooltip: "User readable status",
    label: "Status",
    create: {
      displayOrder: 8
    },
    table: {
      displayOrder: 9
    },
    filter: {
      displayOrder: 9,
      chipTitle: "status = $input",
      columnType: "selectWithStaticValues",
      dropdown: {
        items: [
          'Non-Member',
          'Member',
          'Business Member'
        ]
      },
      filterQuery: {
        $where: {
          status: '$input'
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "gender",
    tooltip: "Gender of this user",
    label: "Gender",
    columnType: "string",
    create: {
      displayOrder: 9
    },
    table: {
      displayOrder: 10
    },
    filter: {
      chipTitle: "gender = $input",
      displayOrder: 10,
      columnType: "selectWithQuery",
      dropdown: {
        endPoint: 'academy_user_reports/by',
        fetchQuery: {
          $distinct: "gender",
          $where: {
            gender: {
              $ilike: "%$input%"
            }
          }
        },
        label: ['gender'],
        value: 'gender',
        labelSeperator: '',
      },
      filterQuery: {
        $where: {
          gender: '$input'
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "company_name",
    tooltip: "Name of this user's company",
    label: "Company Name",
    columnType: "string",
    create: {
      displayOrder: 10
    },
    table: {
      displayOrder: 11
    },
    filter: {
      displayOrder: 11
    }
  },
  {
    dataTableID: "phone",
    tooltip: "Phone number of the user",
    label: "Phone",
    create: {
      displayOrder: 11
    },
    table: {
      displayOrder: 12
    },
    filter: {
      chipTitle: "phone ~= $input",
      displayOrder: 7,
      columnType: "selectWithQuery",
      dropdown: {
        endPoint: "academy_user_reports/by",
        fetchQuery: {
          $where: {
            $or: {
              user_id: {
                $ilike: "%$input%"
              },
              phone: {
                $ilike: "%$input%"
              }
            }
          }
        },
        label: ['user_id', 'phone'],
        value: 'phone',
        labelSeperator: ' - ',
      },
      filterQuery: {
        $where: {
          phone: {
            $ilike: '%$input%'
          }
        }
      },
      selectedFilterInput: '$input'
    }
  },
  {
    dataTableID: "is_dormant",
    tooltip: "Whether the academy is dormant or not?",
    label: "Is Dormant",
    columnType: "boolean",
    create: {
      displayOrder: 12
    },
    table: {
      displayOrder: 13
    },
    filter: {
      displayOrder: 13,
      chipTitle: "is_dormant = $input",
      columnType: "selectWithCustomQuery",
      dropdown: {
        items: [
          {
            label: 'True',
            value: true
          },
          {
            label: 'False',
            value: false
          }
        ]
      },
      filterQueries: {
        true: {
          $where: {
            'is_dormant': true
          }
        },
        false: {
          $where: {
            'is_dormant': false
          }
        }
      }
    },
  },
  {
    dataTableID: "academies_titles",
    tooltip: "Titles of the academies",
    label: "Academies Titles",
    columnType: "string",
    create: {
      displayOrder: 13
    },
    table: {
      displayOrder: 14,
      columnType: "array[string]"
    },
    filter: {
      displayOrder: 14
    }
  },
  {
    dataTableID: "sub_academies_titles",
    tooltip: "Sub-academies of the current academy",
    label: "Sub-academies Titles",
    columnType: "string",
    create: {
      displayOrder: 14
    },
    table: {
      displayOrder: 15,
      columnType: "array[string]"
    },
    filter: {
      displayOrder: 15
    }
  },
  {
    dataTableID: "user_registered_at",
    tooltip: "When was this user registered?",
    label: "User Registered At",
    columnType: "date",
    create: {
      displayOrder: 15
    },
    table: {
      displayOrder: 16
    },
    filter: {
      displayOrder: 16,
      columnType: "dateTimeRange"
    }
  },
  {
    dataTableID: "last_login_at",
    tooltip: "Last login time of this user",
    label: "Last Login At",
    columnType: "date",
    create: {
      displayOrder: 16
    },
    table: {
      displayOrder: 17
    },
    filter: {
      displayOrder: 17,
      columnType: "dateTimeRange"
    }
  },
  {
    dataTableID: "renewal_date",
    tooltip: "User's renewal date",
    label: "Renewal Date",
    columnType: "date",
    create: {
      displayOrder: 17
    },
    table: {
      displayOrder: 18
    },
    filter: {
      displayOrder: 18,
      columnType: "dateRange"
    }
  },
  {
    dataTableID: "enrollments_count",
    tooltip: "Number of enrollments in this academy",
    label: "Enrollments Count",
    columnType: "number",
    create: {
      displayOrder: 18
    },
    table: {
      displayOrder: 19
    },
    filter: {
      displayOrder: 19,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "enrollments_completed_count",
    tooltip: "Number of completed enrollments",
    label: "Enrollments Completed Count",
    columnType: "number",
    create: {
      displayOrder: 19
    },
    table: {
      displayOrder: 20
    },
    filter: {
      displayOrder: 20,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "courses_completed_titles",
    tooltip: "Titles of the completed courses",
    label: "Courses Completed Titles",
    columnType: "string",
    create: {
      displayOrder: 20
    },
    table: {
      displayOrder: 21,
      columnType: "array[string]"
    },
    filter: {
      displayOrder: 20
    }
  },
  {
    dataTableID: "courses_taken_titlles",
    tooltip: "Titles of the taken courses",
    label: "Courses Taken Titles",
    columnType: "string",
    create: {
      displayOrder: 21
    },
    table: {
      displayOrder: 22,
      columnType: "array[string]"
    },
    filter: {
      displayOrder: 21
    }
  },
  {
    dataTableID: "resources_downloaded_count",
    tooltip: "Number of resources downloaded",
    label: "Resources Downloaded Count",
    columnType: "number",
    create: {
      displayOrder: 22
    },
    table: {
      displayOrder: 23
    },
    filter: {
      displayOrder: 23,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "tools_downloaded_count",
    tooltip: "Number of tools downloaded",
    label: "Tools Downloaded Count",
    columnType: "number",
    create: {
      displayOrder: 23
    },
    table: {
      displayOrder: 24
    },
    filter: {
      displayOrder: 24,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "in_academy_toolkits_viewed_count",
    tooltip: "Toolkits viewed count of this academy",
    label: "In Academy Toolkits Viewed Count",
    columnType: "number",
    create: {
      displayOrder: 24
    },
    table: {
      displayOrder: 25
    },
    filter: {
      displayOrder: 25,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "sign_in_count",
    tooltip: "Number of sign ins",
    label: "Sign In Count",
    columnType: "number",
    create: {
      displayOrder: 25
    },
    table: {
      displayOrder: 26
    },
    filter: {
      displayOrder: 26,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "accountibility_partner_email",
    tooltip: "Email of the accountability partner",
    label: "Accountibility Partner Email",
    columnType: "string",
    create: {
      displayOrder: 26
    },
    table: {
      displayOrder: 27
    },
    filter: {
      displayOrder: 26
    }
  },
  {
    dataTableID: "competency",
    tooltip: "User's Competency",
    label: "Competency",
    columnType: "date",
    create: {
      displayOrder: 27
    },
    table: {
      displayOrder: 28
    },
    filter: {
      displayOrder: 27,
      columnType: "dateTimeRange"
    }
  },
  {
    dataTableID: "pdp_status",
    tooltip: "User PDP status",
    label: "PDP Status",
    columnType: "string",
    create: {
      displayOrder: 28
    },
    table: {
      displayOrder: 29
    },
    filter: {
      displayOrder: 28,
      chipTitle: "pdp_status = $input",
      columnType: "selectWithStaticValues",
      dropdown: {
        items: [
          'Incomplete',
          'In Progress',
          'Pending',
          'Approved',
          'Rejected',
          'N/A'
        ]
      },
      filterQuery: {
        $where: {
          pdp_status: '$input'
        }
      },
      selectedFilterInput: '$input'
    },
  },
  {
    dataTableID: "goal_set",
    tooltip: "Number of Set goals",
    label: "Goal Set",
    columnType: "number",
    create: {
      displayOrder: 29
    },
    table: {
      displayOrder: 30
    },
    filter: {
      displayOrder: 30,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "goal_approved",
    tooltip: "Number of approved goals",
    label: "Goal Approved",
    columnType: "number",
    create: {
      displayOrder: 30
    },
    table: {
      displayOrder: 31
    },
    filter: {
      displayOrder: 31,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "posts_count",
    tooltip: "Number of posts",
    label: "Posts Count",
    columnType: "number",
    create: {
      displayOrder: 31
    },
    table: {
      displayOrder: 32
    },
    filter: {
      displayOrder: 32,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "comments_count",
    tooltip: "Number of comments",
    label: "Comments Count",
    columnType: "number",
    create: {
      displayOrder: 32
    },
    table: {
      displayOrder: 33
    },
    filter: {
      displayOrder: 33,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "likes_count",
    tooltip: "Number of likes",
    label: "Likes Count",
    columnType: "number",
    create: {
      displayOrder: 33
    },
    table: {
      displayOrder: 34
    },
    filter: {
      displayOrder: 34,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "shares_count",
    tooltip: "Number of shares",
    label: "Shares Count",
    columnType: "number",
    create: {
      displayOrder: 34
    },
    table: {
      displayOrder: 35
    },
    filter: {
      displayOrder: 35,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "ami_learn_signin",
    tooltip: "Mobile login for learn app of this user",
    label: "AMI Learn Mobile Count",
    columnType: "number",
    create: {
      displayOrder: 35
    },
    table: {
      displayOrder: 36
    },
    filter: {
      displayOrder: 36,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "ami_business_signin",
    tooltip: "Mobile login for business app of this user",
    label: "AMI Business Mobile Count",
    columnType: "number",
    create: {
      displayOrder: 36
    },
    table: {
      displayOrder: 37
    },
    filter: {
      displayOrder: 37,
      columnType: "integerRange"
    }
  },
  {
    dataTableID: "inserted_at",
    tooltip: "When was this record created?",
    label: "Inserted At",
    columnType: "date",
    create: {
      displayOrder: 37
    },
    table: {
      displayOrder: 38
    },
    filter: {
      displayOrder: 38,
      columnType: "dateTimeRange"
    }
  },
  {
    dataTableID: "updated_at",
    tooltip: "When was this record updated?",
    label: "Updated At",
    columnType: "date",
    create: {
      displayOrder: 38
    },
    table: {
      displayOrder: 39
    },
    filter: {
      displayOrder: 39,
      columnType: "dateTimeRange"
    }
  },
  {
    dataTableID: "last_processed_at",
    tooltip: "When was this record last processed?",
    label: "Last Processed At",
    columnType: "date",
    create: {
      displayOrder: 39
    },
    table: {
      displayOrder: 40
    },
    filter: {
      displayOrder: 40,
      columnType: "dateTimeRange"
    }
  }
];

const tabs = [
  {
    view: "detailView",
    entity: "users",
    endPoint: "users/by",
    title: "Details",
    queryFilter: {
      id: ":id"
    }
  }
];

const entity = {
  title: "Academy User Reports",
  name: "academy_user_reports",
  actions: {
    sort: {
      endPoint: "academy_user_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "academy_user_reports/by",
      query: {}
    },
    create: {
      endPoint: "academy_user_reports"
    },
    filter: {
      endPoint: "academy_user_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "academy_user_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "academy_user_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    tenant: {
      endPoint: "academies/by",
      query: {},
      name: "Academies"
    }
  }
};


export { sidebar, entity, keys, tabs };
