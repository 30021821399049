const modules = {}
const requireModule = require.context(
  '@/app/entities',
  true,
  /^.*\.entity.js$/
)

requireModule.keys().forEach((fileName) => {
  const moduleName = fileName.split('/')[1].replace(/(\.\/\|\.js)/g, '').split('.')[0]
  if (moduleName)
  modules[moduleName] = {
    ...requireModule(fileName),
  }
})

export default modules