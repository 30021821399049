<template>
    <div class="w-75 mx-auto">
        <Filters :show-filter-modal="showModal" :fields="fields" :key-to-remove="keyToRemove"
            @applyFilters="doApplyFilters" @closeModal="closeModal" @resetFilters="resetFilters"></Filters>
        <div class="d-flex justify-space-between">
            <div>
                <div v-if="selectedFilters.length != 0">
                    <v-chip v-for="filter in selectedFilters" :key="filter.on" class="ma-2" small close
                        @click:close="removeFilter(filter.key)">
                        {{ `${filter.key}=${filter.value}` }}
                    </v-chip>
                </div>
            </div>
            <div>
                <v-btn @click="openModal" icon>
                    <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
            </div>
        </div>
        <div>
            <AverageSpendingTime :filter-where="this.filters.$where"></AverageSpendingTime>
        </div>
        <div>
            <DailyActiveUsers :filter-where="this.filters.$where"></DailyActiveUsers>
        </div>
        <div>
            <ConversationsPerDay :filter-where="this.filters.$where"></ConversationsPerDay>
        </div>
        <div>
            <TotalGroupMessages :filter-where="this.filters.$where"></TotalGroupMessages>
        </div>
        <div>
            <RatioDailyMonthlyUsers :filter-where="this.filters.$where"></RatioDailyMonthlyUsers>
        </div>
    </div>
</template>

<script>
import AverageSpendingTime from '@/core/views/GraphMessageAnalytics/AverageSpendingTime.vue'
import DailyActiveUsers from '@/core/views/GraphMessageAnalytics/DailyActiveUsers.vue'
import ConversationsPerDay from './ConversationsPerDay.vue'
import TotalGroupMessages from './TotalGroupMessages.vue'
import RatioDailyMonthlyUsers from './RatioDailyMonthlyUsers.vue'
import Filters from './Filters.vue'
import { removeObjectKeys } from "@/core/services/util.js"

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'GraphMessageAnalytics',
    components: {
        AverageSpendingTime,
        DailyActiveUsers,
        ConversationsPerDay,
        TotalGroupMessages,
        Filters,
        RatioDailyMonthlyUsers
    },
    data() {
        return {
            keyToRemove: null,
            filters: { $where: {} },
            showModal: false,
            selectedFilters: [],
            fields: [
                {
                    dataTableID: "date",
                    tooltip: "Date of Active Users",
                    label: "Date",
                    columnType: "date",
                    filter: {
                        displayOrder: 1,
                        columnType: "custom_date"
                    }
                },
                {
                    dataTableID: "academy_id",
                    label: "Academy",
                    columnType: "string",
                    table: {
                        displayOrder: 1
                    },
                    filter: {
                        displayOrder: 1,
                        columnType: "selectWithQuery",
                        dropdown: {
                            endPoint: "academies/by",
                            fetchQuery: {
                                $where: { name: { $ilike: "%$input%" } }
                            },
                            label: ['id', 'name'],
                            value: 'id',
                            labelSeperator: ' - ',
                        },
                        filterQuery: {
                            $where: {
                                academy_id: '%$input%'
                            }
                        },
                        selectedFilterInput: '$input'
                    }
                },
                {
                    dataTableID: "user_id",
                    tooltip: "User ID associated with this academy",
                    label: "User ID",
                    columnType: "number",
                    create: {
                        displayOrder: 2
                    },
                    table: {
                        displayOrder: 3
                    },
                    filter: {
                        chipTitle: "user_id ~= $input",
                        displayOrder: 3,
                        columnType: "selectWithQuery",
                        dropdown: {
                            endPoint: "users/by",
                            fetchQuery: {
                                $where: {
                                    $or: {
                                        id: {
                                            $ilike: "%$input%"
                                        },
                                        name: {
                                            $ilike: "%$input%"
                                        }
                                    }
                                }
                            },
                            label: ['id', 'name'],
                            value: 'id',
                            labelSeperator: ' - ',
                        },
                        filterQuery: {
                            $where: {
                                user_id: '%$input%'
                            }
                        },
                        selectedFilterInput: '$input'
                    }
                },
                {
                    dataTableID: "group_name",
                    tooltip: "Group Name",
                    label: "Group",
                    columnType: "string",
                    table: {
                        displayOrder: 2
                    },
                    filter: {
                        chipTitle: "group ~= $input",
                        displayOrder: 2,
                        columnType: "selectWithQuery",
                        dropdown: {
                            endPoint: "/message_analytics/group_conversations",
                            fetchQuery: {
                                $where: { group_name: { $ilike: "%$input%" } }
                            },
                            label: ['group_name'],
                            value: 'group_name',
                            labelSeperator: ' - ',
                        },
                        filterQuery: {
                            $where: {
                                group_name: {
                                    $ilike: '%$input%'
                                }
                            }
                        },
                        selectedFilterInput: '$input'
                    }

                },
            ]
        }
    },
    methods: {
        removeFilter(key) {
            this.selectedFilters = this.selectedFilters.filter((x) => { return x.key != key })
            this.filters.$where = removeObjectKeys(this.filters.$where, key)
            this.keyToRemove = key

        },
        doApplyFilters(params) {

            let alreadySelectedKeys = this.selectedFilters.map((x) => { return x.key })
            for (let key of Object.keys(params)) {
                if (params[key] !== '') {
                    this.filters.$where = { [key]: params[key], ...this.filters.$where }
                    if (!alreadySelectedKeys.includes(key))
                        this.selectedFilters.push({ "key": key, "value": params[key] })
                }
            }
            this.closeModal()
        },
        openModal() {
            this.showModal = true
        },
        closeModal() {
            this.showModal = false
        },
        resetFilters() {
            this.selectedFilters = []
        }
    }
}
</script>
