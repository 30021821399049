const state = {}
const actions = {}
const getters = {}
const mutations = {}

export default {
  state,
  actions,
  getters,
  mutations
}
