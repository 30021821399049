import getcsvFileKeys from "./entity.utils.js"
const sidebar = true;

const keys = [{
  dataTableID: "academy_id",
  tooltip: "Academy ID of this report",
  label: "Academy ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    chipTitle: "academy_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "academies/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        academy_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "title",
  tooltip: "Title of the course",
  label: "Title",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  },
  filter: {
    chipTitle: "title ~= $input",
    displayOrder: 2,
    filterQuery: {
      $where: {
        title: {
          $ilike: "%$input%"
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "course_type",
  tooltip: "Type of the course",
  label: "Course Type",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    chipTitle: "course_type = $input",
    displayOrder: 3,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_course_period_reports/by',
      fetchQuery: {
        $distinct: "course_type",
        $where: {
          course_type: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['course_type'],
      value: 'course_type',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        course_type: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "course_period_id",
  tooltip: "Course period ID for this record",
  label: "Course Period ID",
  columnType: "number",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    chipTitle: "course_period_id ~= $input",
    displayOrder: 4,
    filterQuery: {
      $where: {
        course_period_id: {
          $ilike: "%$input%"
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "academy_name",
  tooltip: "Academy Name for this course period",
  label: "Academy Name",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  }
},
{
  dataTableID: "academy_type",
  tooltip: "Type of this academy",
  label: "Academy Type",
  columnType: "number",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    chipTitle: "academy_type = $input",
    displayOrder: 3,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_course_period_reports/by',
      fetchQuery: {
        $distinct: "academy_type",
        $where: {
          academy_type: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['academy_type'],
      value: 'academy_type',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        academy_type: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "starts_on",
  tooltip: "Start date of this course period",
  label: "Starts On",
  columnType: "date",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 7,
    columnType: "dateRange"
  }
},
{
  dataTableID: "ends_on",
  tooltip: "End date of this course period",
  label: "Ends On",
  columnType: "date",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  },
  filter: {
    displayOrder: 8,
    columnType: "dateRange"
  }
},
{
  dataTableID: "enrollments_count",
  tooltip: "Number of enrollments in this course period",
  label: "Enrollments Count",
  columnType: "number",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 9,
    columnType: "integerRange"
  }
},
{
  dataTableID: "free_users_count",
  tooltip: "Number of free users in this course period",
  label: "Free Users Count",
  columnType: "number",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    displayOrder: 10,
    columnType: "integerRange"
  }
},
{
  dataTableID: "paid_users_count",
  tooltip: "Number of paid users in this course period",
  label: "Paid Users Count",
  columnType: "number",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    displayOrder: 11,
    columnType: "integerRange"
  }
},
{
  dataTableID: "courses_completed_count",
  tooltip: "Number of courses completed in this course period",
  label: "Courses Completed Count",
  columnType: "number",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    columnType: "integerRange"
  }
},
{
  dataTableID: "assignments_submitted_count",
  tooltip: "Assignment submission count in this course period",
  label: "Assignments Submitted Count",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "assignments_accepted_count",
  tooltip: "Assignment acceptance count of this course period",
  label: "Assignments Accepted Count",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14,
    columnType: "integerRange"
  }
},
{
  dataTableID: "assignments_rejected_count",
  tooltip: "Assignment rejection count of this course period",
  label: "Assignments Rejected Count",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_preassessment_score",
  tooltip: "Avg preassessment score of this course period",
  label: "Average Preassessment Score",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_final_score",
  tooltip: "Final avg score of this course period",
  label: "Average Final Score",
  columnType: "number",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17
  },
  filter: {
    displayOrder: 17,
    columnType: "integerRange"
  }
},
{
  dataTableID: "people_improved_count",
  tooltip: "People improved count of this course period",
  label: "People Improved Count",
  columnType: "number",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 18
  },
  filter: {
    displayOrder: 18,
    columnType: "integerRange"
  }
},
{
  dataTableID: "improvement_percentage",
  tooltip: "Improvement percentage of this course period",
  label: "Improvement Percentage",
  columnType: "number",
  create: {
    displayOrder: 18
  },
  table: {
    displayOrder: 19
  },
  filter: {
    displayOrder: 19,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_one_score",
  tooltip: "Average score of the unit one",
  label: "Average Unit One Score",
  columnType: "number",
  create: {
    displayOrder: 19
  },
  table: {
    displayOrder: 20
  },
  filter: {
    displayOrder: 20,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_two_score",
  tooltip: "Average score of the unit two",
  label: "Average Unit Two Score",
  columnType: "number",
  create: {
    displayOrder: 20
  },
  table: {
    displayOrder: 21
  },
  filter: {
    displayOrder: 21,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_three_score",
  tooltip: "Average score of the unit three",
  label: "Average Unit Three Score",
  columnType: "number",
  create: {
    displayOrder: 21
  },
  table: {
    displayOrder: 22
  },
  filter: {
    displayOrder: 22,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_four_score",
  tooltip: "Average score of the unit four",
  label: "Average Unit Four Score",
  columnType: "number",
  create: {
    displayOrder: 22
  },
  table: {
    displayOrder: 23
  },
  filter: {
    displayOrder: 23,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_five_score",
  tooltip: "Average score of the unit five",
  label: "Average Unit Five Score",
  columnType: "number",
  create: {
    displayOrder: 23
  },
  table: {
    displayOrder: 24
  },
  filter: {
    displayOrder: 24,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_six_score",
  tooltip: "Average score of the unit six",
  label: "Average Unit Six Score",
  columnType: "number",
  create: {
    displayOrder: 24
  },
  table: {
    displayOrder: 25
  },
  filter: {
    displayOrder: 25,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_seven_score",
  tooltip: "Average score of the unit seven",
  label: "Average Unit Seven Score",
  columnType: "number",
  create: {
    displayOrder: 25
  },
  table: {
    displayOrder: 26
  },
  filter: {
    displayOrder: 26,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_eight_score",
  tooltip: "Average score of the unit eight",
  label: "Average Unit Eight Score",
  columnType: "number",
  create: {
    displayOrder: 26
  },
  table: {
    displayOrder: 27
  },
  filter: {
    displayOrder: 27,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_nine_score",
  tooltip: "Average score of the unit nine",
  label: "Average Unit Nine Score",
  columnType: "number",
  create: {
    displayOrder: 27
  },
  table: {
    displayOrder: 28
  },
  filter: {
    displayOrder: 28,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_unit_ten_score",
  tooltip: "Average score of the unit ten",
  label: "Average Unit Ten Score",
  columnType: "number",
  create: {
    displayOrder: 28
  },
  table: {
    displayOrder: 29
  },
  filter: {
    displayOrder: 29,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_assignment_score",
  tooltip: "Average assignment score of this course period",
  label: "Average Assignment Score",
  columnType: "number",
  create: {
    displayOrder: 29
  },
  table: {
    displayOrder: 30
  },
  filter: {
    displayOrder: 30,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_final_exam_score",
  tooltip: "Average final exam score of this course period",
  label: "Average Final Exam Score",
  columnType: "number",
  create: {
    displayOrder: 30
  },
  table: {
    displayOrder: 31
  },
  filter: {
    displayOrder: 31,
    columnType: "integerRange"
  }
},
{
  dataTableID: "enrollments_buddies_count",
  tooltip: "Buddies enrolled count of this course period",
  label: "Enrollments Buddies Count",
  columnType: "number",
  create: {
    displayOrder: 31
  },
  table: {
    displayOrder: 32
  },
  filter: {
    displayOrder: 32,
    columnType: "integerRange"
  }
},
{
  dataTableID: "enrollments_login_count",
  tooltip: "Enrolled login count of this course period",
  label: "Enrollments Login Count",
  columnType: "number",
  create: {
    displayOrder: 32
  },
  table: {
    displayOrder: 33
  },
  filter: {
    displayOrder: 33,
    columnType: "integerRange"
  }
},
{
  dataTableID: "resources_downloaded_count",
  tooltip: "Download resources count in this course period",
  label: "Resources Downloaded Count",
  columnType: "number",
  create: {
    displayOrder: 33
  },
  table: {
    displayOrder: 34
  },
  filter: {
    displayOrder: 34,
    columnType: "integerRange"
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Likes count in this course period",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 34
  },
  table: {
    displayOrder: 35
  },
  filter: {
    displayOrder: 35,
    columnType: "integerRange"
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Count of shares in this course period",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 35
  },
  table: {
    displayOrder: 36
  },
  filter: {
    displayOrder: 36,
    columnType: "integerRange"
  }
},
{
  dataTableID: "posts_count",
  tooltip: "Number of posts in this course period",
  label: "Posts Count",
  columnType: "number",
  create: {
    displayOrder: 36
  },
  table: {
    displayOrder: 37
  },
  filter: {
    displayOrder: 37,
    columnType: "integerRange"
  }
},
{
  dataTableID: "comments_count",
  tooltip: "Number of comments in this course period",
  label: "Comments Count",
  columnType: "number",
  create: {
    displayOrder: 37
  },
  table: {
    displayOrder: 38
  },
  filter: {
    displayOrder: 38,
    columnType: "integerRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  table: {
    displayOrder: 39
  },
  filter: {
    displayOrder: 40,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 39
  },
  filter: {
    displayOrder: 40,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  table: {
    displayOrder: 40
  },
  filter: {
    displayOrder: 40,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "academies",
  endPoint: "courses/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Academy Course Period Reports",
  name: "academy_course_period_reports",
  actions: {
    sort: {
      endPoint: "academy_course_period_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "academy_course_period_reports/by",
      query: {}
    },
    create: {
      endPoint: "academy_course_period_reports"
    },
    filter: {
      endPoint: "academy_course_period_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "academy_course_period_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "academy_course_period_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    tenant: {
      endPoint: "academies/by",
      query: {},
      name: "Academies",
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};
