<template>
    <v-snackbar
        value="snackbar"
        :timeout="3000"
        :color="toastClass(toast.type)"
        top
        right
        absolute
        @input="$emit('removeToast', toast.id)"
      >
        {{ toast.message }}
    </v-snackbar>
</template>

<script>
export default {
    props: {
        toast: {
            type: Object
        }
    },
    methods: {
        toastClass (toastType) {
            switch (toastType) {
                case 'error':
                return 'bg-danger text-light'
                case 'success':
                return 'bg-success text-light'
                default:
                return ''
            }
        },
    },
    computed: {
        snackbar () {
            return !!this.toast
        }
    }
}
</script>

<style lang="scss" scoped>

</style>