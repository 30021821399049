import getcsvFileKeys from "./entity.utils.js"
const sidebar = true;

const keys = [{
  dataTableID: "academy_id",
  tooltip: "Academy ID of this report",
  label: "Academy ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    chipTitle: "academy_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "academies/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        academy_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "academy_name",
  tooltip: "Academy Name for this record",
  label: "Academy Name",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  }
},
{
  dataTableID: "type",
  tooltip: "Type of the academy",
  label: "Type",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    chipTitle: "type = $input",
    displayOrder: 3,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_summary_reports/by',
      fetchQuery: {
        $distinct: "type",
        $where: {
          type: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['type'],
      value: 'type',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        type: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "is_dormant",
  tooltip: "Whether this academy is dormant or not?",
  label: "Is Dormant",
  columnType: "boolean",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4,
    chipTitle: "is_dormant = $input",
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [
        {
          label: 'True',
          value: true
        },
        {
          label: 'False',
          value: false
        }
      ]
    },
    filterQueries: {
      true: {
        $where: {
          'is_dormant': true
        }
      },
      false: {
        $where: {
          'is_dormant': false
        }
      }
    }
  },
},
{
  dataTableID: "moderators",
  tooltip: "Moderators of this academy",
  label: "Moderators",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5,
    columnType: "array[string]"
  },
  filter: {
    chipTitle: "moderators ~= $input",
    displayOrder: 5,
  }
},
{
  dataTableID: "sub_academies_titles",
  tooltip: "Sub-Academies of the Academy",
  label: "Sub-academies Titles",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 6,
    columnType: "array[string]"
  }
},
{
  dataTableID: "users_count",
  tooltip: "Total Number of users in this academy",
  label: "Users Count",
  columnType: "number",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 6,
    columnType: "integerRange"
  }
},
{
  dataTableID: "courses_count",
  tooltip: "Total number of courses in this academy",
  label: "Courses Count",
  columnType: "number",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 8
  },
  filter: {
    displayOrder: 7,
    columnType: "integerRange"
  }
},
{
  dataTableID: "course_periods_count",
  tooltip: "Number of course periods in this academy",
  label: "Course Periods Count",
  columnType: "number",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 8,
    columnType: "integerRange"
  }
},
{
  dataTableID: "enrollments_count",
  tooltip: "Number of enrollments",
  label: "Enrollments Count",
  columnType: "number",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 10
  },
  filter: {
    displayOrder: 9,
    columnType: "integerRange"
  }
},
{
  dataTableID: "enrollments_completed_count",
  tooltip: "Completed enrollments count",
  label: "Enrollments Completed Count",
  columnType: "number",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 11
  },
  filter: {
    displayOrder: 10,
    columnType: "integerRange"
  }
},
{
  dataTableID: "toolkits_count",
  tooltip: "Number of toolkits",
  label: "Toolkits Count",
  columnType: "number",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 11,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_count",
  tooltip: "Number of tools",
  label: "Tools Count",
  columnType: "number",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 12,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_downloaded_count",
  tooltip: "Number of tools downloaded",
  label: "Tools Downloaded Count",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "resources_downloaded_count",
  tooltip: "Number of resources downloaded",
  label: "Resources Downloaded Count",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 14,
    columnType: "integerRange"
  }
},
{
  dataTableID: "posts_count",
  tooltip: "Number of posts",
  label: "Posts Count",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "comments_count",
  tooltip: "Number of comments",
  label: "Comment Count",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 17
  },
  filter: {
    displayOrder: 16,
    columnType: "integerRange"
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Number of likes",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 18
  },
  filter: {
    displayOrder: 17,
    columnType: "integerRange"
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Number of shares",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 19
  },
  filter: {
    displayOrder: 18,
    columnType: "integerRange"
  }
},
{
  dataTableID: "goals_set_count",
  tooltip: "Number of goal sets",
  label: "Goals Set Count",
  columnType: "number",
  create: {
    displayOrder: 18
  },
  table: {
    displayOrder: 20
  },
  filter: {
    displayOrder: 19,
    columnType: "integerRange"
  }
},
{
  dataTableID: "goals_approved_count",
  tooltip: "Approved number of goals",
  label: "Goals Approved Count",
  columnType: "number",
  create: {
    displayOrder: 19
  },
  table: {
    displayOrder: 21
  },
  filter: {
    displayOrder: 20,
    columnType: "integerRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  create: {
    displayOrder: 20
  },
  table: {
    displayOrder: 22
  },
  filter: {
    displayOrder: 21,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  create: {
    displayOrder: 21
  },
  table: {
    displayOrder: 23
  },
  filter: {
    displayOrder: 22,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 24
  },
  filter: {
    displayOrder: 23,
    columnType: "dateTimeRange"
  }
}
];

const entity = {
  title: "Academy Summary Reports",
  name: "academy_summary_reports",
  actions: {
    sort: {
      endPoint: "academy_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "academy_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "academy_summary_reports"
    },
    filter: {
      endPoint: "academy_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "academy_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "academy_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    tenant: {
      endPoint: "academies/by",
      query: {},
      name: "Academies"
    }
  }
};

const tabs = [{
  view: "detailView",
  entity: "users",
  endPoint: "users/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

export {
  sidebar,
  entity,
  keys,
  tabs
};
