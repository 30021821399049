import getcsvFileKeys from "./entity.utils.js"
const sidebar = true

// TODO: Need to fix the display order
const keys = [{
  dataTableID: "user_id",
  label: "User ID",
  columnType: "number",
  tooltip: "User ID for this report",
  table: {
    displayOrder: 1
  },
  filter: {
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "programme_period_user_summary_reports/by",
      fetchQuery: {
        $where: {
          $or: {
            user_id: {
              $ilike: "%$input%"
            },
            user_name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['user_id', 'user_name'],
      value: 'user_id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        user_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "user_name",
  tooltip: "Username of this user",
  label: "User Name",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  }
},
{
  dataTableID: "user_email",
  tooltip: "Email of the user",
  label: "User Email",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    displayOrder: 3
  }
},
{
  dataTableID: "programme_id",
  tooltip: "programme_id of respective programme_period",
  label: "Programme ID",
  columnType: "number",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "programmes/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        programme_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "programme_name",
  tooltip: "Programme Name associated with respective programme",
  label: "Programme Name",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  }
},
// TODO: Need to add End Point for the following entity object.
{
  dataTableID: "programme_period_id",
  tooltip: "Programme period id of the user",
  label: "Program Period ID",
  columnType: "number",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    displayOrder: 6,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "programmes/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        programme_period_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "academy_id",
  tooltip: "Academy id of the user",
  label: "Academy ID",
  columnType: "number",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7,
    columnType: "number",
  },
  filter: {
    displayOrder: 7,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "academies/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        academy_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "academy_name",
  tooltip: "Name of the academy",
  label: "Academy Name",
  columnType: "string",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  }
},
{
  dataTableID: "sub_academies",
  tooltip: "Sub academies associated with the academies",
  label: "Sub Academies",
  columnType: "string",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9,
    columnType: "array[map]",
    display: ["name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 9,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Sub Academies',
        value: 'with_sub_academies'
      },
      {
        label: 'Without Sub Academies',
        value: 'without_sub_academies'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_sub_academies: {
        $where: {
          sub_academies: {
            $not_equal: []
          }
        }
      },
      without_sub_academies: {
        $where: {
          sub_academies: []
        }
      },
    }
  }
},
{
  dataTableID: "is_graduated",
  tooltip: "Whether the user is graduated or not?",
  label: "Is Graduated",
  columnType: "boolean",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    displayOrder: 10,
    chipTitle: "is_graduated = $input",
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [
        {
          label: 'True',
          value: true
        },
        {
          label: 'False',
          value: false
        }
      ]
    },
    filterQueries: {
      true: {
        $where: {
          'is_graduated': true
        }
      },
      false: {
        $where: {
          'is_graduated': false
        }
      }
    }
  },
},
{
  dataTableID: "total_sign_in",
  tooltip: "Total sign_in in this programme period",
  label: "Total Sign In",
  columnType: "number",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    displayOrder: 11,
    columnType: "integerRange"
  }
},
{
  dataTableID: "last_sign_in",
  tooltip: "When was user last sign in?",
  label: "Last Sign In",
  columnType: "date",
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "current_progress",
  tooltip: "Current progress of programme period of user",
  label: "Current Progress",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13,
    columnType: "number"
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "current_phase",
  tooltip: "Current phase of programme period",
  label: "Current Phase",
  columnType: "string",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14
  }
},
{
  dataTableID: "phases_completed_count",
  tooltip: "Number of phases completed by user of this propgramme period",
  label: "Phases Completed Count",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15,
    columnType: "number"
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "total_downloaded_tools_and_resources_count",
  tooltip: "Total tools and recources downloaded by user of this programme period",
  label: "Total Downloaded Tools and Resources Count",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16,
    columnType: "number"
  },
  filter: {
    displayOrder: 16,
    columnType: "integerRange"
  }
},
{
  dataTableID: "completion_percent",
  tooltip: "Phase Wise Completion Percent",
  label: "Phase Completion Percent",
  columnType: "array[map]",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17,
    columnType: "array[map]",
    display: ["phase_name", "completion_percent"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},

{
  dataTableID: "total_sign_ins",
  tooltip: "Phase Wise Total sign ins",
  label: "Phase Total sign ins",
  columnType: "array[map]",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 18,
    columnType: "array[map]",
    display: ["phase_name", "total_sign_ins"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},
{
  dataTableID: "total_downloaded_tools",
  tooltip: "Phase Wise total downloaded tools",
  label: "Phase total downloaded tools",
  columnType: "array[map]",
  create: {
    displayOrder: 18
  },
  table: {
    displayOrder: 19,
    columnType: "array[map]",
    display: ["phase_name", "total_downloaded_tools"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},
{
  dataTableID: "recent_downloaded_tools",
  tooltip: "Phase Wise Recent downloaded tools",
  label: "Phase Recent downloaded tools",
  columnType: "array[map]",
  create: {
    displayOrder: 19
  },
  table: {
    displayOrder: 20,
    columnType: "array[map]",
    display: ["phase_name", "recent_downloaded_tools"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},
{
  dataTableID: "phase_components",
  tooltip: "Phase Components",
  label: "Phase Components",
  columnType: "array[map]",
  create: {
    displayOrder: 20
  },
  table: {
    displayOrder: 21,
    columnType: "array[map]",
    display: ["phase_name", "phase_components"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},
{
  dataTableID: "downloded_resources",
  tooltip: "Phase Wise Download Resources",
  label: "Phase Download Resources",
  columnType: "array[map]",
  create: {
    displayOrder: 21
  },
  table: {
    displayOrder: 22,
    columnType: "array[map]",
    display: ["phase_name", "downloded_resources"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},
{
  dataTableID: "completed_course_periods_count",
  tooltip: "Phase Wise Completed Course Counts",
  label: "Phase Completed Course Counts",
  columnType: "array[map]",
  create: {
    displayOrder: 22
  },
  table: {
    displayOrder: 23,
    columnType: "array[map]",
    display: ["phase_name", "completed_course_periods_count"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},
{
  dataTableID: "enrolled_course_periods_count",
  tooltip: "Phase Wise Enrolled Courses",
  label: "Phase Enrolled Courses",
  columnType: "array[map]",
  create: {
    displayOrder: 23
  },
  table: {
    displayOrder: 24,
    columnType: "array[map]",
    display: ["phase_name", "enrolled_course_periods_count"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  }
},
{
  dataTableID: "courses_added_to_lj",
  tooltip: "Learning journey courses",
  label: "Courses Added to LJ",
  columnType: "string",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10,
    columnType: "array[map]",
    display: ["name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 10,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Courses Added to LJ',
        value: 'with_Courses_Added_to_LJ'
      },
      {
        label: 'Without Courses Added to LJ',
        value: 'Without_Courses_Added_to_LJ'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_Courses_Added_to_LJ: {
        $where: {
          courses_added_to_lj: {
            $not_equal: []
          }
        }
      },
      Without_Courses_Added_to_LJ: {
        $where: {
          courses_added_to_lj: []
        }
      },
    }
  }
},
{
  dataTableID: "tools_added_to_lj",
  tooltip: "Learning journey tools",
  label: "Tools Added to LJ",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11,
    columnType: "array[map]",
    display: ["tool_name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 11,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Tools Added to LJ',
        value: 'With_Tool_Added_to_LJ'
      },
      {
        label: 'Without Tools Added to LJ',
        value: 'Without_Tools_Added_to_LJ'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      With_Tool_Added_to_LJ: {
        $where: {
          tool_added_to_lj: {
            $not_equal: []
          }
        }
      },
      Without_Tools_Added_to_LJ: {
        $where: {
          tool_added_to_lj: []
        }
      },
    }
  }
},
{
  dataTableID: "toolkits_added_to_lj",
  tooltip: "Learning journey courses",
  label: "Toolkits Added to LJ",
  columnType: "string",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12,
    columnType: "array[map]",
    display: ["toolkit_name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 12,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Toolkit Added To Lj',
        value: 'with_toolkit_added_to_lj'
      },
      {
        label: 'Without Toolkit Added To Lj',
        value: 'without_toolkit_added_to_lj'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      'with_toolkit_added_to_lj': {
        $where: {
          'toolkit_added_to_lj': {
            $not_equal: []
          }
        }
      },
      without_toolkit_added_to_lj: {
        $where: {
          toolkit_added_to_lj: []
        }
      },
    }
  }
},
{
  dataTableID: "event_added_to_lj",
  tooltip: "Learning journey events",
  label: "Events Added to LJ",
  columnType: "string",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13,
    columnType: "array[map]",
    display: ["name"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 13,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Event Added To Lj',
        value: 'with_event_added_to_lj'
      },
      {
        label: 'Without Event Added To Lj',
        value: 'without_event_added_to_lj'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_event_added_to_lj: {
        $where: {
          event_added_to_lj: {
            $not_equal: []
          }
        }
      },
      without_event_added_to_lj: {
        $where: {
          event_added_to_lj: []
        }
      },
    }
  }
},
{
  dataTableID: "event_added_to_LJ_count",
  tooltip: "Number of the events",
  label: "Events Added to LJ Count",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14,
    columnType: "integer"
  },
  filter: {
    displayOrder: 14,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Event Added To Lj Count',
        value: 'with_event_added_to_LJ_count'
      },
      {
        label: 'Without Event Added To Lj Count',
        value: 'without_event_added_to_LJ_count'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_event_added_to_LJ_count: {
        $where: {
          event_added_to_LJ_count: {
            $not_equal: []
          }
        }
      },
      without_event_added_to_LJ_count: {
        $where: {
          event_added_to_LJ_count: []
        }
      },
    }
  }
},
{
  dataTableID: "core_components_completed_count",
  tooltip: "Number of the core components completed",
  label: "Core Components Completed Count",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "core_components_completed_percentage",
  tooltip: "Percentage of the core components completed",
  label: "Core Components Completed Percentage",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "integerRange"
  }
},
{
  dataTableID: "core_components_completed",
  tooltip: "All components which are mandatory and completed",
  label: "Core Components Completed",
  columnType: "number",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17,
    columnType: "array[map]",
    display: ["title", "component_type"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 17,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Core Components Completed',
        value: 'with_core_components_completed'
      },
      {
        label: 'Without Core Components Completed',
        value: 'without_core_components_completed'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_core_components_completed: {
        $where: {
          core_components_completed: {
            $not_equal: []
          }
        }
      },
      without_core_components_completed: {
        $where: {
          core_components_completed: []
        }
      },
    }
  }
},
{
  dataTableID: "core_components_not_completed",
  tooltip: "All components which are mandatory but not completed",
  label: "Core Components not Completed",
  columnType: "number",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 18,
    columnType: "array[map]",
    display: ["title", "component_type"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 18,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Core Components Not Completed',
        value: 'with_core_components_not_completed'
      },
      {
        label: 'Without Core Components Not Completed',
        value: 'without_core_components_not_completed'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_core_components_not_completed: {
        $where: {
          core_components_not_completed: {
            $not_equal: []
          }
        }
      },
      without_core_components_not_completed: {
        $where: {
          core_components_not_completed: []
        }
      },
    }
  }
},
{
  dataTableID: "core_components_not_completed_count",
  tooltip: "Count of the core components not completed",
  label: "Core Components not Working Count",
  columnType: "number",
  create: {
    displayOrder: 18
  },
  table: {
    displayOrder: 19
  },
  filter: {
    displayOrder: 19,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_downloaded_count",
  tooltip: "Number of tools downloaded",
  label: "Tool Downloaded Count",
  columnType: "number",
  create: {
    displayOrder: 19
  },
  table: {
    displayOrder: 20
  },
  filter: {
    displayOrder: 20,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_viewed_count",
  tooltip: "Number of tools viewed",
  label: "Tools Viewed Count",
  columnType: "number",
  create: {
    displayOrder: 20
  },
  table: {
    displayOrder: 21
  },
  filter: {
    displayOrder: 21,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_liked_count",
  tooltip: "Number of tools liked",
  label: "Tools Liked Count",
  columnType: "number",
  create: {
    displayOrder: 21
  },
  table: {
    displayOrder: 22
  },
  filter: {
    displayOrder: 22,
    columnType: "integerRange"
  }
},
{
  dataTableID: "tools_count",
  tooltip: "Number of tools",
  label: "Tools Count",
  columnType: "number",
  create: {
    displayOrder: 22
  },
  table: {
    displayOrder: 23
  },
  filter: {
    displayOrder: 23,
    columnType: "integerRange"
  }
},
{
  dataTableID: "toolkits_count",
  tooltip: "Number of toolkits",
  label: "Toolkits Count",
  columnType: "number",
  create: {
    displayOrder: 23
  },
  table: {
    displayOrder: 24
  },
  filter: {
    displayOrder: 24,
    columnType: "integerRange"
  }
},
{
  dataTableID: "toolkits_viewed_count",
  tooltip: "Number of toolkits viewed",
  label: "Toolkits Viewed Count",
  columnType: "number",
  create: {
    displayOrder: 24
  },
  table: {
    displayOrder: 25
  },
  filter: {
    displayOrder: 25,
    columnType: "integerRange"
  }
},
{
  dataTableID: "courses_count",
  tooltip: "Number of courses",
  label: "Courses Count",
  columnType: "number",
  create: {
    displayOrder: 25
  },
  table: {
    displayOrder: 26
  },
  filter: {
    displayOrder: 26,
    columnType: "integerRange"
  }
},
{
  dataTableID: "web_login_count",
  tooltip: "Number of Web Logins",
  label: "Web Login Count",
  columnType: "number",
  create: {
    displayOrder: 26
  },
  table: {
    displayOrder: 27
  },
  filter: {
    displayOrder: 27,
    columnType: "integerRange"
  }
},
{
  dataTableID: "ami_learn_app_login_count",
  tooltip: "Number of AMI Learn App Login",
  label: "AMI Learn App Login Count",
  columnType: "number",
  create: {
    displayOrder: 27
  },
  table: {
    displayOrder: 28
  },
  filter: {
    displayOrder: 28,
    columnType: "integerRange"
  }
},
{
  dataTableID: "ami_business_app_login_count",
  tooltip: "Number of Business App Login",
  label: "AMI Business App Login Count",
  columnType: "number",
  create: {
    displayOrder: 28
  },
  table: {
    displayOrder: 29
  },
  filter: {
    displayOrder: 29,
    columnType: "integerRange"
  }
},
{
  dataTableID: "starts_on",
  tooltip: "Start date of this programme period",
  label: "Starts On",
  columnType: "date",
  create: {
    displayOrder: 29
  },
  table: {
    displayOrder: 30
  },
  filter: {
    displayOrder: 30,
    columnType: "dateRange"
  }
},
{
  dataTableID: "ends_on",
  tooltip: "End date of this programme period",
  label: "Ends On",
  columnType: "date",
  create: {
    displayOrder: 31
  },
  table: {
    displayOrder: 32
  },
  filter: {
    displayOrder: 32,
    columnType: "dateRange"
  }
},
{
  dataTableID: "is_dormant",
  tooltip: "Whether the academy is dormant or not?",
  label: "Is Dormant",
  columnType: "boolean",
  create: {
    displayOrder: 33
  },
  table: {
    displayOrder: 34
  },
  filter: {
    displayOrder: 34,
    chipTitle: "is_dormant = $input",
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [
        {
          label: 'True',
          value: true
        },
        {
          label: 'False',
          value: false
        }
      ]
    },
    filterQueries: {
      true: {
        $where: {
          'is_dormant': true
        }
      },
      false: {
        $where: {
          'is_dormant': false
        }
      }
    }
  },
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  table: {
    displayOrder: 34
  },
  filter: {
    displayOrder: 35,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  table: {
    displayOrder: 35
  },
  filter: {
    displayOrder: 36,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  table: {
    displayOrder: 36
  },
  filter: {
    displayOrder: 37,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "academies",
  endPoint: "courses/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Programme Period User Summary Reports",
  name: "programme_period_user_summary_reports",
  actions: {
    sort: {
      endPoint: "programme_period_user_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "programme_period_user_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "programme_period_user_summary_reports"
    },
    filter: {
      endPoint: "programme_period_user_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "programme_period_user_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "programme_period_user_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    tenant: {
      endPoint: "academies/by",
      query: {},
      name: "Academies",
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};
