const sidebar = false;

const entity = {
  title: "Tools",
  actions: {
    sort: {
      endPoint: "tools/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "tools/by",
      query: {}
    },
    create: {
      endPoint: "tools"
    },
    filter: {
      endPoint: "tools/by",
      query: {
        $group: "id",
        $where: {}
      }
    }
  }
};

const keys = [{
  dataTableID: "id",
  tooltip: "ID to generate this report",
  label: "Id",
  columnType: "string",
  table: {
    displayOrder: 1
  },
  filter: {
    displayOrder: 1,
    columnType: "select",
    matchType: "", // Values can be gte, lte, gt, lt, By default it is eq
    options: {
      select: [{
        field: "id"
      }],
      title: ["id"],
      value: "id",
      endPoint: "users/by",
      separator: " ",
      initializer: ""
    }
  }
},
{
  dataTableID: "title",
  tooltip: "Title of this tool",
  label: "Title",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  },
  filter: {
    displayOrder: 2
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "tools",
  endPoint: "tools/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

export {
  sidebar,
  entity,
  keys,
  tabs
};