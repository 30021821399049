import { BASE_URL } from "@/.env";
import { CommonService } from "./common.service";
import { by, post } from "./custom.service";
import { getEndpointAndQuery } from "@/core/services/util.js"

export default class EntityService extends CommonService {
  static loadEntityDetails(endPoint, query = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(this.errorWrapper(error));
        });
    });
  }
  static createEntityData(endPoint, data = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }  
 
  static getInputRecords(endPoint, query = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static downloadEntityCsv(endPoint, query = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static emailEntityCsv(endPoint, query = {}) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint, query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static getTenantList(endPoint) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + endPoint)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static SearchEntityRelated(entityName, inputKey) {
    let queryAble = getEndpointAndQuery(entityName, inputKey)
    return new Promise((resolve, reject) => {
      return by(BASE_URL + queryAble.Endpoint, queryAble.Query)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static GenerateReportService(ReportAction, ReportType) {
    return new Promise((resolve, reject) => {
      return post(BASE_URL + `jobs/trigger/${ReportAction}/${ReportType}`)
        .then(response => {
          resolve(response.data.records);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static searchAcademyByName(searchQuery) {
    return new Promise((resolve, reject) => {
      return by(BASE_URL + `academies/by`, { $where: { name: { $ilike: `%${searchQuery}%` } } })
        .then(response => {
          resolve(response.data.records);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
