import EntityService from "@/core/services/entity.service";
export default {
    namespaced: true,

    state: {
        currentTenant: {},
    },

    mutations: {
        setCurrentTenant(state, payload) {
            state.currentTenant = payload.currentTenant
        }
    },
    actions: {
        setCurrentTenant({ commit }, currentTenant) {
            localStorage.setItem("currentTenant", currentTenant)
            commit("setCurrentTenant", { currentTenant: currentTenant });
        },
        getTenantList({ commit }, tenant) {
            return new Promise((resolve, reject) => {
                EntityService.getTenantList(
                    tenant.endPoint, {
                    ...tenant.query,
                    $limit: 10,
                    $skip: 0,
                }
                )
                    .then(response => {
                        resolve(response.data.records)
                    })
                    .catch(error => {
                        commit("toast/newToast", { type: "error", message: error.message });
                        reject(error)
                    });
            })
        }
    }
}