import getcsvFileKeys from "./entity.utils.js"
let sidebar;
const is_admin = window.localStorage.getItem('is_admin')

if (is_admin == 'false') {
  sidebar = false;
} else if (is_admin == 'true') {
  sidebar = true;
}

const keys = [{
  dataTableID: "course_id",
  label: "Course ID",
  tooltip: "Course ID of this report",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    chipTitle: "course_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "courses/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            title: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'title'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        course_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "course_name",
  tooltip: "Name of the course",
  label: "Course Name",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  }
},
{
  dataTableID: "course_periods_count",
  tooltip: "Number of course period count",
  label: "Course Periods Count",
  columnType: "number",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    displayOrder: 3,
    columnType: "integerRange"
  }
},
{
  dataTableID: "academy_course_periods_count",
  tooltip: "Number of academy course period count",
  label: "Academy Course Periods Count",
  columnType: "number",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4,
    columnType: "integerRange"
  }
},
{
  dataTableID: "lj_course_periods_count",
  tooltip: "Number of course periods in Learning Journey",
  label: "LJ Course Periods Count",
  columnType: "number",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  },
  filter: {
    displayOrder: 5,
    columnType: "integerRange"
  }
},
{
  dataTableID: "public_course_periods_count",
  tooltip: "Number of course periods that are pubiic",
  label: "Public Course Periods Count",
  columnType: "number",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    displayOrder: 6,
    columnType: "integerRange"
  }
},
{
  dataTableID: "learning_lab_course_periods_count",
  tooltip: "Number of course periods in Learning Lab",
  label: "Learning Lab Course Periods Count",
  columnType: "number",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 7,
    columnType: "integerRange"
  }
},
{
  dataTableID: "latest_course_period",
  tooltip: "Very recent course period",
  label: "Latest Course Period",
  columnType: "date",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  },
  filter: {
    displayOrder: 8,
    columnType: "dateRange",
  }
},
{
  dataTableID: "academies_count",
  tooltip: "Number of academies",
  label: "Academies Count",
  columnType: "number",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 9,
    columnType: "integerRange"
  }
},
{
  dataTableID: "academies_titles",
  tooltip: "Titles of the academies",
  label: "Academies Titles",
  columnType: "string",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10,
    columnType: "array[string]"
  },
  filter: {
    displayOrder: 10,
  }
},
{
  dataTableID: "learning_lab_titles",
  tooltip: "Titles of the learning labs",
  label: "Learning Lab Titles",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11,
    columnType: "array[string]"
  },
  filter: {
    displayOrder: 11,
  }
},
{
  dataTableID: "type",
  tooltip: "Type of the course",
  label: "Type",
  columnType: "string",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    chipTitle: "type = $input",
    columnType: "selectWithStaticValues",
    dropdown: {
      items: [
        'Access',
        'Free',
        'Certificate'
      ]
    },
    filterQuery: {
      $where: {
        type: '$input'
      }
    },
    selectedFilterInput: '$input'
  },
},
{
  dataTableID: "users_count",
  tooltip: "Number of users",
  label: "Users Count",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "paid_users_count",
  tooltip: "Number of paid users",
  label: "Paid Users Count",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14,
    columnType: "integerRange"
  }
},
{
  dataTableID: "free_users_count",
  tooltip: "Number of free users",
  label: "Free Users Count",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "courses_completed_count",
  tooltip: "Number of completed courses",
  label: "Courses Completed Count",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "integerRange"
  }
},
{
  dataTableID: "enrollments_count",
  tooltip: "Number of enrollments",
  label: "Enrollments Count",
  columnType: "number",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17
  },
  filter: {
    displayOrder: 17,
    columnType: "integerRange"
  }
},
{
  dataTableID: "enrollments_buddies_count",
  tooltip: "Number of enrolled buddies",
  label: "Enrollments Buddies Count",
  columnType: "number",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 18
  },
  filter: {
    displayOrder: 18,
    columnType: "integerRange"
  }
},
{
  dataTableID: "people_imporved_count",
  tooltip: "People improved count of this course period",
  label: "People Improved Count",
  columnType: "number",
  create: {
    displayOrder: 18
  },
  table: {
    displayOrder: 19
  },
  filter: {
    displayOrder: 19,
    columnType: "integerRange"
  }
},
{
  dataTableID: "improvement_percentage",
  tooltip: "Improvement percentage of this course period",
  label: "Improvement Percentage",
  columnType: "number",
  create: {
    displayOrder: 19
  },
  table: {
    displayOrder: 20
  },
  filter: {
    displayOrder: 20,
    columnType: "integerRange"
  }
},
{
  dataTableID: "avg_pre_assessment_score",
  tooltip: "Average score of the preassessment",
  label: "Average Pre-Assessment Score",
  columnType: "number",
  create: {
    displayOrder: 20
  },
  table: {
    displayOrder: 21
  },
  filter: {
    displayOrder: 21,
    columnType: "integerRange"
  }
},
{
  dataTableID: "average_final_grade",
  tooltip: "Average final grade calculated",
  label: "Average Final Grade",
  columnType: "number",
  create: {
    displayOrder: 21
  },
  table: {
    displayOrder: 22
  },
  filter: {
    displayOrder: 22,
    columnType: "integerRange"
  }
},
{
  dataTableID: "posts_count",
  tooltip: "Number of posts",
  label: "Posts Count",
  columnType: "number",
  create: {
    displayOrder: 22
  },
  table: {
    displayOrder: 23
  },
  filter: {
    displayOrder: 23,
    columnType: "integerRange"
  }
},
{
  dataTableID: "comments_count",
  tooltip: "Number of comments",
  label: "Comments Count",
  columnType: "number",
  create: {
    displayOrder: 23
  },
  table: {
    displayOrder: 24
  },
  filter: {
    displayOrder: 24,
    columnType: "integerRange"
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Number of likes",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 24
  },
  table: {
    displayOrder: 25
  },
  filter: {
    displayOrder: 25,
    columnType: "integerRange"
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Number of shares",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 25
  },
  table: {
    displayOrder: 26
  },
  filter: {
    displayOrder: 26,
    columnType: "integerRange"
  }
},
{
  dataTableID: "downloads_count",
  tooltip: "Number of downloads",
  label: "Downloads Count",
  columnType: "number",
  create: {
    displayOrder: 26
  },
  table: {
    displayOrder: 27
  },
  filter: {
    displayOrder: 27,
    columnType: "integerRange"
  }
},
{
  dataTableID: "course_created_at",
  tooltip: "When was this course created?",
  label: "Course Created At",
  columnType: "date",
  create: {
    displayOrder: 27
  },
  table: {
    displayOrder: 28
  },
  filter: {
    displayOrder: 28,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  create: {
    displayOrder: 28
  },
  table: {
    displayOrder: 29
  },
  filter: {
    displayOrder: 29,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  create: {
    displayOrder: 29
  },
  table: {
    displayOrder: 30
  },
  filter: {
    displayOrder: 30,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  create: {
    displayOrder: 30
  },
  table: {
    displayOrder: 31
  },
  filter: {
    displayOrder: 31,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "academies",
  endPoint: "courses/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Course Summary Reports",
  name: "course_summary_reports",
  actions: {
    sort: {
      endPoint: "course_summary_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "course_summary_reports/by",
      query: {}
    },
    create: {
      endPoint: "course_summary_reports"
    },
    filter: {
      endPoint: "course_summary_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "course_summary_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "course_summary_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};
