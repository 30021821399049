import getcsvFileKeys from "./entity.utils.js"
const sidebar = true;

const keys = [{
  dataTableID: "academy_id",
  tooltip: "Academy ID of this report",
  label: "Academy ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    chipTitle: "academy_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "academies/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        academy_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "academy_name",
  tooltip: "Academy Name of the academy_tool",
  label: "Academy Name",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  }
},
{
  dataTableID: "tool_id",
  tooltip: "Tool ID of the academy_tool",
  label: "Tool ID",
  columnType: "number",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    chipTitle: "tool_id ~= $input",
    displayOrder: 3,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'tools/by',
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            title: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'title'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        tool_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "tool_title",
  tooltip: "Title of the academy_tool",
  label: "Tool Title",
  columnType: "string",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  }
},
{
  dataTableID: "tool_description",
  tooltip: "Description of the respective academy_tool",
  label: "Tool Description",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  },
  filter: {
    chipTitle: "tool_description ~= $input",
    displayOrder: 5,
    filterQuery: {
      $where: {
        tool_description: {
          $ilike: "%$input%"
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "tool_type",
  tooltip: "Type of this academy_tool",
  label: "Tool Type",
  columnType: "string",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6
  },
  filter: {
    chipTitle: "tool_type = $input",
    displayOrder: 6,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_tool_reports/by',
      fetchQuery: {
        $distinct: "tool_type",
        $where: {
          tool_type: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['tool_type'],
      value: 'tool_type',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        tool_type: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "created_by",
  tooltip: "Who created this report?",
  label: "Created By",
  columnType: "string",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    chipTitle: "created_by = $input",
    displayOrder: 7,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_tool_reports/by',
      fetchQuery: {
        $distinct: "created_by",
        $where: {
          created_by: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['created_by'],
      value: 'created_by',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        created_by: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "toolkits_titles",
  tooltip: "Titles of the toolkits",
  label: "Toolkits Titles",
  columnType: "string",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8,
    columnType: "array[string]"
  },
  filter: {
    chipTitle: "toolkits_titles ~= $input",
    displayOrder: 8,
  }
},
{
  dataTableID: "tool_created_at",
  tooltip: "When was this academy_tool created?",
  label: "Tool Created at",
  columnType: "date",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    chipTitle: "tool_created_at ~= $input",
    displayOrder: 9,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "tool_last_accessed",
  tooltip: "Who accessed this academy_tool recently?",
  label: "Tool Last Accessed",
  columnType: "date",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 10
  }
},
{
  dataTableID: "tool_label",
  tooltip: "Label of this academy_tool",
  label: "Label",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    chipTitle: "tool_label = $input",
    displayOrder: 11,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_tool_reports/by',
      fetchQuery: {
        $distinct: "tool_label",
        $where: {
          tool_label: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['tool_label'],
      value: 'tool_label',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        tool_label: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "tool_downloads_count",
  tooltip: "Download count of this academy_tool",
  label: "Tool Downloads Count",
  columnType: "number",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    chipTitle: "tool_downloads_count ~= $input",
    displayOrder: 12,
    columnType: "integerRange"
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Likes count of this academy_tool",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    chipTitle: "likes_count ~= $input",
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Share count of this academy_tool",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    chipTitle: "shares_count ~= $input",
    displayOrder: 14,
    columnType: "integerRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "date",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17
  },
  filter: {
    displayOrder: 17,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "academies",
  endPoint: "courses/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Academy Tool Reports",
  name: "academy_tool_reports",
  actions: {
    sort: {
      endPoint: "academy_tool_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "academy_tool_reports/by",
      query: {}
    },
    create: {
      endPoint: "academy_tool_reports"
    },
    filter: {
      endPoint: "academy_tool_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "academy_tool_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "academy_tool_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    tenant: {
      endPoint: "academies/by",
      query: {},
      name: "Academies"
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};
