const URL_SCHEME = process.env.VUE_APP_URL_SCHEME || 'http';
const SOCKET_SCHEME = process.env.VUE_APP_SOCKET_SCHEME || 'ws';
const API_URL = process.env.VUE_APP_ADMIN_API_URL || `localhost:4005`;
// const BASE_URL = 'http://dashboard-staging.africanmanagers.org/admin/api/';
// const BASE_URL = 'http://localhost:4005/api/';
const BASE_URL = process.env.VUE_APP_ADMIN_API_BASE_URL || `${URL_SCHEME}://${API_URL}/api/`;
const DOMAIN_TITLE = 'AMI Admin';
export {
    BASE_URL,
    DOMAIN_TITLE,
    URL_SCHEME,
    SOCKET_SCHEME,
    API_URL
};
