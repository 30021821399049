const Reports = [
  {
    _name: "CSidebarNavItem",
    text: "Academy course period",
    to: "/academy_course_period",
    ReportAction: "AcademyCoursePeriodReport",
    icon: "mdi-label",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Academy summary",
    to: "/academy_summary",
    ReportAction: "AcademySummaryReport",
    icon: "mdi-label",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Academy tool",
    to: "/academy_tool",
    ReportAction: "AcademyToolReport",
    icon: "mdi-label",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Academy toolkit",
    to: "/academy_toolkit",
    icon: "mdi-label",
    ReportAction: "AcademyToolKitReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Academy user",
    to: "/academy_user",
    icon: "mdi-label",
    ReportAction: "AcademyUserReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Company report",
    to: "/company_report",
    icon: "mdi-label",
    ReportAction: "CompanyReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Course Period Summary",
    to: "/course_Period_Summary",
    ReportAction: "CoursePeriodSummaryReport",
    icon: "mdi-label",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Course Summary",
    to: "/course_Summary",
    icon: "mdi-label",
    ReportAction: "CourseSummaryReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Course period user summary",
    to: "/course_period_user_summary",
    icon: "mdi-label",
    ReportAction: "CoursePeriodUserSummaryReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Programme period summary",
    to: "/programme_period_summary",
    icon: "mdi-label",
    ReportAction: "ProgrammePeriodSummaryReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Programme period user summary",
    to: "/programme_period_user_summary",
    icon: "mdi-label",
    ReportAction: "ProgrammePeriodUserSummaryReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Tool Summary",
    to: "/tool_Summary",
    icon: "mdi-label",
    ReportAction: "ToolSummaryReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "Tootkit Summary",
    to: "/tootkit_Summary",
    icon: "mdi-label",
    ReportAction: "ToolKitSummaryReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "User Summary",
    to: "/user_Summary",
    icon: "mdi-label",
    ReportAction: "UserSummaryReport",
    exact: false
  },
  {
    _name: "CSidebarNavItem",
    text: "User profile summary",
    to: "/user_profile_summary",
    icon: "mdi-label",
    ReportAction: "UserProfileSummaryReport",
    exact: false
  }
];
export default Reports;
