<template>
    <!-- <v-dialog @update:show="handleEntityModal" v-model="dialog" width="520"> -->
    <v-dialog persistent no-click-animation v-model="showFilterModal" width="520">
        <v-card>
            <v-card-title>
                <span>Filter {{ filterName }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <template>
                    <form ref="formData form-position" :key="`formData${formKey}`">

                        <div>
                            <section v-for="field in fields" :key="field.dataTableID">
                                <h3>{{ field.firstName }}</h3>
                                <InputField :action="'filter'" :field="field" v-model="formData[field.dataTableID]" />
                            </section>
                        </div>
                    </form>
                </template>
            </v-card-text>
            <v-divider class="divider-margin-top"></v-divider>
            <v-card-actions class="v-card-filter-btns pb-4">
                <div class="d-flex py-3 pl-2">
                    <v-btn @click="emitFilterData" color="info" class="mr-1">
                        <span v-if="!is_loading">Confirm</span>
                        <v-progress-circular v-if="is_loading" indeterminate color="primary"></v-progress-circular>
                    </v-btn>
                </div>
                <div class="d-flex py-3 pl-2">
                    <button @click="resetForm" class="btn btn-secondary mr-1">
                        Reset
                    </button>
                    <button @click="closeModal" class="btn btn-secondary">
                        Cancel
                    </button>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import { mapState } from 'vuex'
export default {
    name: 'filters',
    computed: {
        ...mapState({ showModal: (state) => state.showModal })
    },
    components: {
        InputField: () => import("@/core/components/base/InputField"),
    },
    watch: {
        keyToRemove: {
            handler(value) {
                if (value) this.removeKeyFromFilter(value)
            }
        }
    },
    props: {
        fields: { type: Array, default: () => [] },
        filterName: { type: String, default: 'Records' },
        showFilterModal: { type: Boolean, default: false },
        keyToRemove: { type: Number, default: 0 }
    },
    data() {
        return {
            is_loading: false, formData: {}, formKey: 0
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal')
        },
        emitFilterData() {
            this.$emit('applyFilters', this.formData)
        },
        removeKeyFromFilter(keyToRemove) {
            let data = {}
            for (let key in Object.keys(this.formData)) {
                if (key != keyToRemove) {
                    data = { ...data, ...this.formData[key] }
                }
            }
            this.formData = data
            this.formKey++
        },
        resetForm() {
            this.formData = {}
            this.formKey++
        }
    }
}
</script>