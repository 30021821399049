<template>
    <div>
        <div v-if="isLoading" class="text-center">
            <v-progress-circular :size="100" color="#f87979" indeterminate></v-progress-circular>
        </div>
        <div v-else>
            <div class="text-center mb-4">
                <h2>Average Spending Time</h2>
            </div>
            <div>
                <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                    :width="width" :height="height" />
            </div>

        </div>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { mapActions } from "vuex";


import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { removeObjectKeys } from '../../services/util';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'AverageSpendingTime',
    components: {
        Bar,
    },
    watch: {
        filterWhere: {
            deep: true,
            handler(value) {
                this.filters.$where = value
                this.filters.$where = removeObjectKeys(this.filters.$where, 'date')
                this.filters.$where = removeObjectKeys(this.filters.$where, 'group_name')
                this._getAverageSpendingTime()
            },
        }
    },
    created() {
        this._getAverageSpendingTime()
    },
    props: {
        filterWhere: {
            type: Object,
            default: () => { }
        },
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: 'text-center mb-10',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isLoading: true,
            filters: { $where: {} },
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Average Spending Time Percentage',
                        backgroundColor: '#f87979',
                        data: [],
                        barThickness: 20,
                        base: 0,
                        barStart: 0,
                        barEnd: 100
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },

    methods: {
        _getAverageSpendingTime(params = { $limit: 10, $skip: 0 }) {
            this.isLoading = true
            params = { ...params, ...this.filters }
            this.getAverageSpendingTime(params).then((res) => {
                let slots = res.map(x => x.slot)
                let counts = res.map(x => x.count)
                let sum = counts.reduce((acc, count) => acc + count, 0)
                let data = []
                counts.forEach(count => {
                    data.push(Number((count / sum) * 100).toFixed(2))
                });
                this.chartData.labels = slots
                this.chartData.datasets[0].data = data
                this.isLoading = false

            }).catch(() => this.isLoading = false)
        },
        ...mapActions(["getAverageSpendingTime"]),
    }
}
</script>
