<template>
  <div>
    <template v-if="this.$slots.layout">
      <slot name="layout"></slot>
    </template>
    <template v-else>
      <dashboard-layout>
        <template v-slot:header>
          <AppHeader> </AppHeader>
        </template>
      </dashboard-layout>
    </template>
  </div>
</template>

<script>
import DashboardLayout from "../../core/layouts/DashboardLayout";
import AppHeader from "../../core/components/header/Header";
export default {
  components: {
    DashboardLayout,
    AppHeader,
  },
};
</script>


