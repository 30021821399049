import Vue from 'vue'
import App from './app/App.vue'
import router from './app/router/routes'
import store from './core/store'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './core/assets/icons/icons.js'
import vuetify from './plugins/vuetify';
import { HTTP } from "./core/services/base.service"
import Socket from "./socket"
Vue.config.productionTip = false

Vue.use(CoreuiVue)
Vue.use(Socket)
HTTP.interceptors.response.use((response) => {
  return response;
}, function (error) {
  // Do something with response error
  if (error.response.status === 401) {
    store.commit("auth/LOGOUT_CURRENT_USER")
    router.replace({ name: "login" })
  }
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  icons,
  vuetify,
  render: h => h(App)
}).$mount('#app')
