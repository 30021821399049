import { by } from './custom.service'

export class UsersService {
    static get entity() {
        return 'users'
    }

    static login(email, password) {
        return new Promise((resolve, reject) => {
            return by('/login ', { login: email, password })
                .then((response) => {
                    // login successful if there's a jwt token in the response
                    const data = {
                        user: response.data.user,
                        jwt: response.data.token,
                        is_admin: response.data.is_admin
                    }
                    
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(data.user))
                    localStorage.setItem('is_admin', JSON.stringify(data.is_admin))
                    
                    resolve(response, data)
                    
                })
                .catch((error) => {
                    const message = ''
                    reject(error, message)
                })
        })
    }
  
}
