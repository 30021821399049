import getcsvFileKeys from "./entity.utils.js"
const sidebar = true;

const keys = [{
  dataTableID: "id",
  tooltip: "ID to generate this report",
  label: "Id",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    //chipTitle: "academy_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "oban_jobs/by",
      fetchQuery: {
        $where: {
          id: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['id'],
      value: 'id',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "state",
  tooltip: "State",
  label: "State",
  columnType: "string",
  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  },
  filter: {
    displayOrder: 2,
    columnType: "selectWithStaticValues",
    dropdown: {
      items: [
        'available',
        'scheduled',
        'executing',
        'retryable',
        'completed',
        'cancelled',
        'discarded'
      ]
    },
    filterQuery: {
      $where: {
        state: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "queue",
  tooltip: "Queue",
  label: "Queue",
  columnType: "string",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  }
},
{
  dataTableID: "worker",
  tooltip: "Worker",
  label: "Worker",
  columnType: "string",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  },
  filter: {
    displayOrder: 4,
    columnType: "selectWithStaticValues",
    dropdown: {
      items: [
        "Main.Worker.AcademyCoursePeriodReport",
        "Oban.Worker.AcademyCoursePeriodReport",
        "Main.Worker.AcademySummaryReport",
        "Oban.Worker.AcademySummaryReport",
        "Main.Worker.AcademyToolKitReport",
        "Oban.Worker.AcademyToolKitReport",
        "Main.Worker.AcademyToolReport",
        "Oban.Worker.AcademyToolReport",
        "Main.Worker.AcademyUserReport",
        "Oban.Worker.AcademyUserReport",
        "Main.Worker.CourseSummaryReport",
        "Oban.Worker.CourseSummaryReport",
        "Main.Worker.ToolKitSummaryReport",
        "Oban.Worker.ToolKitSummaryReport",
        "Main.Worker.ToolSummaryReport",
        "Oban.Worker.ToolSummaryReport",
        "Main.Worker.UserProfileSummaryReport",
        "Bridge.Worker.UserProfileSummaryReport",
        "Oban.Worker.UserProfileSummaryReport",
        "Main.Worker.UserSummaryReport",
        "Bridge.Worker.UserSummaryReport",
        "Oban.Worker.UserSummaryReport",
        "Main.Worker.CoursePeriodUserSummaryReport",
        "Oban.Worker.CoursePeriodUserSummaryReport",
        "Main.Worker.ProgrammePeriodUserSummaryReport",
        "Oban.Worker.ProgrammePeriodUserSummaryReport",
        "Main.Worker.CompanyReport",
        "Oban.Worker.CompanyReport",
        "Main.Worker.ProgrammePeriodSummaryReport",
        "Oban.Worker.ProgrammePeriodSummaryReport"
      ]
    },
    filterQuery: {
      $where: {
        worker: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "args",
  tooltip: "Arguments",
  label: "Arguments",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  },
  filter: {
    label: "Arguments by ID",
    chipTitle: "args = $input",
    columnType: "number",
    displayOrder: 5,
    filterQuery: {
      $group: "id",
      $where: {
        "args": {
          $ilike: "%$input%"
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "errors",
  tooltip: "Errors",
  label: "Errors",
  columnType: "string",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6,
    columnType: "array[map]",
    display: ["at", "attempt"],
    onHoverDisplay: "*",
    displaySeprator: ", "
  },
  filter: {
    displayOrder: 6,
    columnType: "selectWithCustomQuery",
    dropdown: {
      items: [{
        label: '-----',
        value: 'all'
      },
      {
        label: 'With Errors',
        value: 'with_errors'
      },
      {
        label: 'Without Errors',
        value: 'without_errors'
      }
      ],
    },
    filterQueries: {
      all: {
        $where: {}
      },
      with_errors: {
        $where: {
          errors: {
            $not_equal: []
          }
        }
      },
      without_errors: {
        $where: {
          errors: []
        }
      },
    }
  }
},
{
  dataTableID: "attempt",
  tooltip: "Attempts",
  label: "Attempts",
  columnType: "integer",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  }
},
{
  dataTableID: "max_attempt",
  tooltip: "Maximum Attempts",
  label: "Maximum Attempts",
  columnType: "integer",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  }
},
{
  dataTableID: "attempted_by",
  tooltip: "Who attempted this?",
  label: "Attempted By",
  columnType: "string",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9,
    columnType: "array[string]"
  }
},
{
  dataTableID: "priority",
  tooltip: "Priority",
  label: "Priority",
  columnType: "integer",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  }
},
{
  dataTableID: "tags",
  tooltip: "Tags",
  label: "Tags",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  }
},
{
  dataTableID: "meta",
  tooltip: "Meta",
  label: "Meta",
  columnType: "string",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12,
    columnType: "string"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "Inserted At",
  label: "Inserted At",
  columnType: "date",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 13
  }
},
{
  dataTableID: "scheduled_at",
  tooltip: "Scheduled At",
  label: "Scheduled At",
  columnType: "date",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 14
  }
},
{
  dataTableID: "attempted_at",
  tooltip: "Attempted At",
  label: "Attempted At",
  columnType: "date",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 15
  }
},
{
  dataTableID: "completed_at",
  tooltip: "Completed At",
  label: "Completed At",
  columnType: "date",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 16
  }
},
{
  dataTableID: "discarded_at",
  tooltip: "Discarded At",
  label: "Discarded At",
  columnType: "date",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 17
  }
},
{
  dataTableID: "cancelled_at",
  tooltip: "Cancelled At",
  label: "Cancelled At",
  columnType: "date",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 17
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 17
  }
}
];

const entity = {
  title: "Oban Jobs",
  actions: {
    sort: {
      endPoint: "oban_jobs/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "oban_jobs/by",
      query: {}
    },
    create: {
      endPoint: "oban_jobs"
    },
    filter: {
      endPoint: "oban_jobs/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "oban_jobs/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
  }
};
const tabs = [{
  view: "detailView",
  entity: "academies",
  endPoint: "courses/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

export {
  sidebar,
  entity,
  keys,
  tabs
};
