<template>
    <div>
        <div v-if="loading" class="text-center">
            <v-progress-circular :size="100" color="#f87979" indeterminate></v-progress-circular>
        </div>
        <div v-else>
            <div class="text-center mb-4">
                <h2>Ratio Daily Monthly Users</h2>
            </div>
            <div>
                <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
                    :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles"
                    :width="width" :height="height" />
            </div>
            <div>
                <v-pagination v-if="showPagination" class="py-5 mt-5" v-model="page" :length="totalPages"
                    :total-visible="7"></v-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { removeObjectKeys } from '../../services/util';
import { Bar } from 'vue-chartjs/legacy'
import { mapActions } from "vuex";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'RatioDailyMonthlyUsers',
    components: {
        Bar,
    },
    watch: {
        page: {
            handler(value) {
                this._getRatioDailyMonthlyUsers({ $limit: 10, $skip: (value - 1) * 10 })
            },
        },
        filterWhere: {
            deep: true,
            handler(value) {
                this.filters.$where = value
                this.filters.$where = removeObjectKeys(this.filters.$where, 'group_name')

                this._getRatioDailyMonthlyUsers()
            },
        }
    },
    created() {
        this._getRatioDailyMonthlyUsers()
    },
    props: {
        filterWhere: {
            type: Object,
            default: () => { }
        },
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: 'text-center mb-10',
            type: String
        },
        styles: {
            type: Object,
            default: () => { }
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            defaultSorting: { $order: { date: "$desc" } },
            filters: { $where: {} },
            page: 1,
            showPagination: false,
            totalPages: 0,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Daily Active Users',
                        backgroundColor: '#f87979',
                        data: [],
                        barThickness: 20,
                        base: 0,
                        barStart: 0,
                        barEnd: 100
                    },
                    {
                        label: 'Monthly Active Users',
                        backgroundColor: '#d67e7e',
                        data: [],
                        barThickness: 20,
                        base: 0,
                        barStart: 0,
                        barEnd: 100
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            },
        }
    },

    methods: {
        _getRatioDailyMonthlyUsers(params = { $limit: 10, $skip: 0 }) {
            this.loading = true
            params = { ...params, ...this.defaultSorting, ...this.filters }
            this.getRatioDailyMonthlyUsers(params).then((res) => {
                let meta = res.meta
                if (meta.count > 10) this.showPagination = true
                res = res.records
                let dates = res.map(x => x.date)
                let daily_active_users = res.map(x => x.daily_active_users)
                let monthly_active_users = res.map(x => x.monthly_active_users)

                this.totalPages = Math.ceil(meta.count / meta.limit);
                this.chartData.labels = dates
                this.chartData.datasets[0].data = daily_active_users
                this.chartData.datasets[1].data = monthly_active_users
                this.loading = false

            }).catch(() => this.loading = false)
        },
        ...mapActions(["getRatioDailyMonthlyUsers"]),
    }
}
</script>
