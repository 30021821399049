import Vue from 'vue'
import VueRouter from 'vue-router'

import MainLayout from '../layouts/MainLayout.vue'
import Login from "../pages/Login.vue"
import store from "../../core/store"
const Dashboard = () => import('@/core/views/Dashboard.vue')
import ReportGen from "@/core/views/ReportGen.vue"
import Index from "@/core/views/GraphMessageAnalytics/Index.vue"
const Entity = () => import('../pages/entity/Entity.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login,
    name: "login"
  },
  {
    path: '/',
    redirect: 'dashboard',
    component: MainLayout,
    meta: {
      isProtected: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          isProtected: true
        },
      },
      {
        path: '/reports_generator',
        name: 'generate',
        component: ReportGen
      },
      {
        path: '/message_analytics',
        name: 'Message Analytics',
        component: Index,
      },
      {
        path: '/:entity',
        name: 'common-page',
        component: Entity,
        meta: {
          isProtected: true
        },
      },
      {
        path: '/:entity/:id',
        name: 'common-tabs-content',
        component: Entity,
        meta: {
          isProtected: true
        },
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.isProtected)) {
    if (localStorage.getItem('jwt') == null || !store.state.auth?.currentUser?.id) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
