<template>
  <v-app>
    <div class="c-app">
      <AppSidebar />
      <CWrapper>
        <template v-if="this.$slots.header">
          <slot name="header"></slot>
        </template>
        <template v-else>          
          <AppHeader />
        </template>
        <div class="c-body">
          <main class="c-main">
            <CContainer fluid>
              <transition name="fade" mode="out-in">
                <router-view :key="$route.path"></router-view>
              </transition>
            </CContainer>
          </main>
        </div>
        <AppFooter />
      </CWrapper>
      <Toaster />
    </div>
  </v-app>
</template>

<script>
import Toaster from "./../components/base/Toaster";
import AppHeader from "./../components/header/Header.vue";
import AppSidebar from "./../components/sidebar/Sidebar.vue";
import AppFooter from "./../components/footer/Footer.vue";

export default {
  components: {
    AppHeader,
    AppSidebar,
    AppFooter,
    Toaster,
  },
};
</script>

<style lang="scss">
@import "../assets/scss/style";
</style>
