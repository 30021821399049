<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    const authToken = localStorage.getItem("jwt") || null;
    this.$connectSocket("/socket", authToken);
    const channel = this.$socket.channel(
      `user:${JSON.parse(localStorage.getItem("user")).id}`
    );
    channel.join();
    channel.on("download_ready", (payload) => {
      const url = window.URL.createObjectURL(new Blob([payload.file_data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", payload.file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$store.commit("toast/newToast", {
        type: "success",
        message: "File processing completed, its getting downloaded",
      });
    });
    channel.on("email_sent", () => {
      this.$store.commit("toast/newToast", {
        type: "success",
        message: "File processing completed, Email has been sent!",
      });
    });
  },
};
</script>

<style lang="scss">
</style>
