import getcsvFileKeys from "./entity.utils.js"
const sidebar = true;

const keys = [{
  dataTableID: "academy_id",
  tooltip: "Academy ID of this report",
  label: "Academy ID",
  columnType: "number",
  table: {
    displayOrder: 1
  },
  filter: {
    chipTitle: "academy_id ~= $input",
    displayOrder: 1,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: "academies/by",
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            name: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'name'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        academy_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "academy_name",
  tooltip: "Academy Name of this academy_toolkit",
  label: "Academy Name",
  columnType: "string",

  create: {
    displayOrder: 1
  },
  table: {
    displayOrder: 2
  }
},
{
  dataTableID: "toolkit_id",
  tooltip: "Toolkit ID of this academy_toolkit",
  label: "Toolkit ID",
  columnType: "number",
  create: {
    displayOrder: 2
  },
  table: {
    displayOrder: 3
  },
  filter: {
    chipTitle: "toolkit_id ~= $input",
    displayOrder: 3,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'toolkits/by',
      fetchQuery: {
        $where: {
          $or: {
            id: {
              $ilike: "%$input%"
            },
            title: {
              $ilike: "%$input%"
            }
          }
        }
      },
      label: ['id', 'title'],
      value: 'id',
      labelSeperator: ' - ',
    },
    filterQuery: {
      $where: {
        toolkit_id: {
          $ilike: '%$input%'
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "toolkit_name",
  tooltip: "Name of the academy_toolkit",
  label: "Toolkit Name",
  columnType: "string",
  create: {
    displayOrder: 3
  },
  table: {
    displayOrder: 4
  }
},
{
  dataTableID: "toolkit_description",
  tooltip: "Description of the academy_toolkit",
  label: "Toolkit Description",
  columnType: "string",
  create: {
    displayOrder: 4
  },
  table: {
    displayOrder: 5
  },
  filter: {
    chipTitle: "toolkit_description ~= $input",
    displayOrder: 5,
    filterQuery: {
      $where: {
        toolkit_description: {
          $ilike: "%$input%"
        }
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "tool_titles",
  tooltip: "Titles of the tools",
  label: "Tool Titles",
  columnType: "string",
  create: {
    displayOrder: 5
  },
  table: {
    displayOrder: 6,
    columnType: "array[string]"
  },
  filter: {
    chipTitle: "tool_titles ~= $input",
    displayOrder: 8,
  }
},
{
  dataTableID: "toolkit_tools_count",
  tooltip: "Number of the toolkit tools",
  label: "Toolkit Tools Count",
  columnType: "number",
  create: {
    displayOrder: 6
  },
  table: {
    displayOrder: 7
  },
  filter: {
    displayOrder: 7,
    columnType: "integerRange"
  }
},
{
  dataTableID: "created_by",
  tooltip: "Who created this academy_toolkit?",
  label: "Created By",
  columnType: "string",
  create: {
    displayOrder: 7
  },
  table: {
    displayOrder: 8
  },
  filter: {
    chipTitle: "created_by = $input",
    displayOrder: 8,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_toolkit_reports/by',
      fetchQuery: {
        $distinct: "created_by",
        $where: {
          created_by: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['created_by'],
      value: 'created_by',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        created_by: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "toolkit_created_at",
  tooltip: "When was this academy_toolkits created?",
  label: "Toolkit Created At",
  columnType: "date",
  create: {
    displayOrder: 8
  },
  table: {
    displayOrder: 9
  },
  filter: {
    displayOrder: 9,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "toolkit_last_accessed",
  tooltip: "Who accessed this academy_toolkit recently?",
  label: "Toolkit Last Accessesd",
  columnType: "date",
  create: {
    displayOrder: 9
  },
  table: {
    displayOrder: 10
  },
  filter: {
    columnType: "dateTimeRange",
    displayOrder: 10
  }
},
{
  dataTableID: "toolkit_label",
  tooltip: "Label of the academy_toolkit",
  label: "Toolkit Label",
  columnType: "string",
  create: {
    displayOrder: 10
  },
  table: {
    displayOrder: 11
  },
  filter: {
    chipTitle: "toolkit_label = $input",
    displayOrder: 11,
    columnType: "selectWithQuery",
    dropdown: {
      endPoint: 'academy_toolkit_reports/by',
      fetchQuery: {
        $distinct: "toolkit_label",
        $where: {
          toolkit_label: {
            $ilike: "%$input%"
          }
        }
      },
      label: ['toolkit_label'],
      value: 'toolkit_label',
      labelSeperator: '',
    },
    filterQuery: {
      $where: {
        toolkit_label: '$input'
      }
    },
    selectedFilterInput: '$input'
  }
},
{
  dataTableID: "tools_downloaded_count",
  tooltip: "Number of Tools downloaded",
  label: "Tools Downloaded Count",
  columnType: "number",
  create: {
    displayOrder: 11
  },
  table: {
    displayOrder: 12
  },
  filter: {
    displayOrder: 12,
    columnType: "integerRange"
  }
},
{
  dataTableID: "likes_count",
  tooltip: "Number of likes",
  label: "Likes Count",
  columnType: "number",
  create: {
    displayOrder: 12
  },
  table: {
    displayOrder: 13
  },
  filter: {
    displayOrder: 13,
    columnType: "integerRange"
  }
},
{
  dataTableID: "shares_count",
  tooltip: "Number of shares",
  label: "Shares Count",
  columnType: "number",
  create: {
    displayOrder: 13
  },
  table: {
    displayOrder: 14
  },
  filter: {
    displayOrder: 14,
    columnType: "integerRange"
  }
},
{
  dataTableID: "toolkit_viewed_count",
  tooltip: "Toolkit's viewed count",
  label: "Toolkit Viewed Count",
  columnType: "number",
  create: {
    displayOrder: 14
  },
  table: {
    displayOrder: 15
  },
  filter: {
    displayOrder: 15,
    columnType: "integerRange"
  }
},
{
  dataTableID: "inserted_at",
  tooltip: "When was this record created?",
  label: "Inserted At",
  columnType: "number",
  create: {
    displayOrder: 15
  },
  table: {
    displayOrder: 16
  },
  filter: {
    displayOrder: 16,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "updated_at",
  tooltip: "When was this record updated?",
  label: "Updated At",
  columnType: "date",
  create: {
    displayOrder: 16
  },
  table: {
    displayOrder: 17
  },
  filter: {
    displayOrder: 17,
    columnType: "dateTimeRange"
  }
},
{
  dataTableID: "last_processed_at",
  tooltip: "When was this record last processed?",
  label: "Last Processed At",
  columnType: "date",
  create: {
    displayOrder: 17
  },
  table: {
    displayOrder: 18
  },
  filter: {
    displayOrder: 18,
    columnType: "dateTimeRange"
  }
}
];

const tabs = [{
  view: "detailView",
  entity: "toolkits",
  endPoint: "toolkits/by",
  title: "Details",
  queryFilter: {
    id: ":id"
  }
}];

const entity = {
  title: "Academy Toolkit Reports",
  name: "academy_toolkit_reports",
  actions: {
    sort: {
      endPoint: "academy_toolkit_reports/by",
      query: {
        $order: {},
      }
    },
    get: {
      endPoint: "academy_toolkit_reports/by",
      query: {}
    },
    create: {
      endPoint: "academy_toolkit_reports"
    },
    filter: {
      endPoint: "academy_toolkit_reports/by",
      query: {
        $group: "id",
        $where: {}
      }
    },
    csvDownload: {
      endPoint: "academy_toolkit_reports/csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    csvEmail: {
      endPoint: "academy_toolkit_reports/background_csv_by",
      query: {
        $group: "id",
        $where: {},
        csvFileKeys: getcsvFileKeys(keys)
      }
    },
    tenant: {
      endPoint: "academies/by",
      query: {},
      name: "Academies"
    }
  }
};

export {
  sidebar,
  entity,
  keys,
  tabs
};
