export default function getcsvFileKeys(keys) {
  let result = [];
  result = keys.map(key => {
    return {
      title: key.label,
      field: key.dataTableID,
      order: key.table.displayOrder
    };
  });
  return result;
}
